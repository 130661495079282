import { useState, useEffect } from 'react';
import { Calendar, AlertCircle, CheckCircle } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { TaskCard } from '../components/TaskCard';
import { TaskProofPopup } from '../components/TaskProofPopup';
import { dailyTasks } from '../data/tasks';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import toast from 'react-hot-toast';
import { useNotification } from '../contexts/NotificationContext';

interface CompletedTask {
  id: string;
  status: string;
}

export function TasksPage() {
  const { userStats, completeTaskForToday, fetchUserStats } = useApp();
  const { user } = useAuth();
  const { addNotification } = useNotification();
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [completedTasks, setCompletedTasks] = useState<CompletedTask[]>([]);
  const [dayCompleted, setDayCompleted] = useState(false);
  const [showCompleteDayPopup, setShowCompleteDayPopup] = useState(false);
  const [taskFilter, setTaskFilter] = useState<string>('all'); // Task filter state

  useEffect(() => {
    if (!user) return;

    const loadCompletedTasks = async () => {
      const q = query(
        collection(db, 'taskSubmissions'),
        where('userId', '==', user.uid),
        where('date', '==', new Date().toISOString().split('T')[0])
      );

      const snapshot = await getDocs(q);
      setCompletedTasks(snapshot.docs.map((doc) => ({
        id: doc.data().taskId as string,
        status: doc.data().status as string,
      })));

      const hasCompletedDay =
        userStats.lastTaskDate?.toDate().toISOString().split('T')[0] ===
        new Date().toISOString().split('T')[0];
      setDayCompleted(hasCompletedDay);
    };

    loadCompletedTasks();
  }, [user, userStats]);

  const handleSubmitProof = async (proof: string) => {
    if (!user || !selectedTask) return;

    try {
      await addDoc(collection(db, 'taskSubmissions'), {
        userId: user.uid,
        taskId: selectedTask.id.toString(),
        proof,
        status: 'pending',
        date: new Date().toISOString().split('T')[0],
        timestamp: serverTimestamp(),
      });

      setCompletedTasks((prev) => [...prev, { id: selectedTask.id.toString(), status: 'pending' }]);
      toast.success('Task proof submitted successfully! Waiting for admin approval.');
    } catch (error) {
      console.error('Error submitting proof:', error);
      toast.error('Failed to submit proof');
    }
  };

  const handleCompleteDay = async () => {
    if (completedTasks.some((task) => task.status !== 'approved')) {
      toast.error('All tasks must be approved by an admin before completing the day.');
      return;
    }

    await completeTaskForToday();
    toast.success('Day completed! Streak updated.');
    fetchUserStats();
    setDayCompleted(true);
    setShowCompleteDayPopup(false);

    // Send notification when all tasks are completed
    addNotification({
      id: `task-completed-${new Date().toISOString()}`,
      type: 'task',
      title: 'Day Completed!',
      message: 'You have completed all your tasks for the day. Great job!',
      timestamp: new Date(),
      read: false,
    });
  };

  const tasksWithStatus = dailyTasks.map((task) => ({
    ...task,
    id: task.id.toString(),
    completed: completedTasks.some((completed) => completed.id === task.id.toString()),
    status: completedTasks.find((completed) => completed.id === task.id.toString())?.status || null,
  }));

  const filteredTasks = tasksWithStatus.filter((task) => {
    if (taskFilter === 'all') return true;
    return task.status === taskFilter;
  });

  const completedCount = completedTasks.filter((task) => task.status === 'approved').length;
  const progressPercentage = (completedCount / dailyTasks.length) * 100;

  useEffect(() => {
    if (completedCount === dailyTasks.length && !dayCompleted) {
      setShowCompleteDayPopup(true);
    }
  }, [completedCount, dayCompleted]);

  return (
    <div className="space-y-6">
      {/* Progress Header */}
      <div className="bg-gradient-to-br from-purple-900/20 via-slate-900/20 to-blue-900/20 rounded-2xl border border-slate-800/50 p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold">Daily Progress</h2>
          <div className="flex items-center gap-2 bg-slate-800/50 px-4 py-2 rounded-full">
            <Calendar className="w-4 h-4 text-purple-400" />
            <span>Day {userStats.daysCompleted?.toString() || '0'}/7</span>
          </div>
        </div>

        <div className="bg-slate-800/50 rounded-lg p-4">
          <div className="flex justify-between items-center text-sm mb-2">
            <span className="text-slate-400">Daily Progress</span>
            <span className="text-purple-400 font-medium">
              {completedCount}/{dailyTasks.length} Approved
            </span>
          </div>
          <div className="bg-slate-700/50 rounded-full h-2">
            <div
              className="bg-gradient-to-r from-purple-500 to-blue-500 h-full rounded-full transition-all duration-500"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>
      </div>

      {/* Filter for Tasks */}
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-bold">Task Filter</h3>
        <div className="flex gap-4">
          <button
            onClick={() => setTaskFilter('all')}
            className={`px-4 py-2 rounded-lg ${taskFilter === 'all' ? 'bg-blue-500 text-white' : 'bg-slate-700 text-slate-400'}`}
          >
            All
          </button>
          <button
            onClick={() => setTaskFilter('pending')}
            className={`px-4 py-2 rounded-lg ${taskFilter === 'pending' ? 'bg-blue-500 text-white' : 'bg-slate-700 text-slate-400'}`}
          >
            Pending
          </button>
          <button
            onClick={() => setTaskFilter('approved')}
            className={`px-4 py-2 rounded-lg ${taskFilter === 'approved' ? 'bg-blue-500 text-white' : 'bg-slate-700 text-slate-400'}`}
          >
            Approved
          </button>
        </div>
      </div>

      {/* Tasks List */}
      <div className="space-y-4">
        {filteredTasks.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            onSubmitProof={() => setSelectedTask(task)}
            status={task.status}
          />
        ))}
      </div>

      {/* Alert for task completion */}
      <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-4">
        <div className="flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-blue-400 mt-0.5" />
          <div>
            <p className="text-sm text-slate-300">
              Complete all daily tasks to maintain your streak and maximize rewards.
              All submissions are verified by admins before rewards are credited.
            </p>
          </div>
        </div>
      </div>

      {selectedTask && (
        <TaskProofPopup
          isOpen={true}
          onClose={() => setSelectedTask(null)}
          task={selectedTask}
          onSubmit={handleSubmitProof}
        />
      )}

      {/* Complete Day Popup */}
      {showCompleteDayPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
            <div className="flex items-center justify-center mb-4">
              <CheckCircle className="w-10 h-10 text-green-500" />
              <h2 className="text-xl font-bold ml-3">Completed Tasks</h2>
            </div>
            <p className="text-gray-600 mb-6">
              All tasks for today are completed and approved. Your daily streak has been updated!
            </p>
            <button
              onClick={handleCompleteDay}
              className="w-full bg-green-500 text-white rounded-lg py-2 hover:bg-green-600 transition-colors"
            >
              Mark Day as Completed
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
