import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import { useStreak } from '../hooks/useStreak';
import {
  Sparkles,
  Target,
  Users,
  Coins,
  ArrowRight,
  Trophy,
  Medal,
  Award,
  CheckCircle,
  Edit,
  Share2,
  X,
  MessageSquare,
} from 'lucide-react';
import { StreakBrokenPopup } from '../components/StreakBrokenPopup';
import { PromoteSection } from '../components/PromoteSection';
import { NewsSection } from '../components/NewsSection';

interface LeaderboardEntry {
  rank: number;
  username: string;
  solanaBalance: number;
  telegramUsername: string;
  avatar: string;
  email: string;
}

export function HomePage() {
  const { userStats } = useApp();
  const navigate = useNavigate();
  const { streakBroken, handleRestartStreak } = useStreak();
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [serverTime, setServerTime] = useState(new Date());
  const [localTime, setLocalTime] = useState(new Date());

  useEffect(() => {
    const fetchServerTime = () => {
      setServerTime(new Date());
    };

    fetchServerTime();
    const interval = setInterval(() => {
      setLocalTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const dailyTasks = [
    { title: "Quiz Master", description: "Answer a quick 5-question quiz.", icon: <CheckCircle className="w-6 h-6 text-green-500" /> },
    { title: "Social Sharer", description: "Share a post on social media.", icon: <Share2 className="w-6 h-6 text-blue-500" /> },
    { title: "Feedback Loop", description: "Provide feedback on today’s feature.", icon: <Edit className="w-6 h-6 text-purple-500" /> },
    { title: "Community Helper", description: "Help answer questions in the forum.", icon: <MessageSquare className="w-6 h-6 text-orange-500" /> },
  ];

  const features = [
    { icon: Target, title: "Daily Tasks", description: "Complete tasks and earn SOL.", color: "text-green-500", bgColor: "bg-green-500/10" },
    { icon: Users, title: "Community Support", description: "Connect with others.", color: "text-blue-500", bgColor: "bg-blue-500/10" },
    { icon: Coins, title: "Earn Rewards", description: "Get rewarded for each task.", color: "text-purple-500", bgColor: "bg-purple-500/10" },
  ];

  const leaderboardData: LeaderboardEntry[] = [
    { rank: 1, username: "SolChamp", solanaBalance: 1500, telegramUsername: "@SolChamp", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=SolChamp", email: "solchamp@example.com" },
    { rank: 2, username: "CryptoKing", solanaBalance: 1450, telegramUsername: "@CryptoKing", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=CryptoKing", email: "cryptoking@example.com" },
    { rank: 3, username: "BlockMaster", solanaBalance: 1400, telegramUsername: "@BlockMaster", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=BlockMaster", email: "blockmaster@example.com" },
    { rank: 4, username: "TokenQueen", solanaBalance: 1350, telegramUsername: "@TokenQueen", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=TokenQueen", email: "tokenqueen@example.com" },
    { rank: 5, username: "SatoshiFan", solanaBalance: 1300, telegramUsername: "@SatoshiFan", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=SatoshiFan", email: "satoshifan@example.com" },
    { rank: 6, username: "CryptoWizard", solanaBalance: 1250, telegramUsername: "@CryptoWizard", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=CryptoWizard", email: "cryptowizard@example.com" },
    { rank: 7, username: "BitLord", solanaBalance: 1200, telegramUsername: "@BitLord", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=BitLord", email: "bitlord@example.com" },
    { rank: 8, username: "NakamotoFan", solanaBalance: 1150, telegramUsername: "@NakamotoFan", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=NakamotoFan", email: "nakamotofan@example.com" },
    { rank: 9, username: "EtherKing", solanaBalance: 1100, telegramUsername: "@EtherKing", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=EtherKing", email: "etherking@example.com" },
    { rank: 10, username: "SolanaQueen", solanaBalance: 1050, telegramUsername: "@SolanaQueen", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=SolanaQueen", email: "solanaqueen@example.com" },
    { rank: 11, username: "DogeFan", solanaBalance: 1000, telegramUsername: "@DogeFan", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=DogeFan", email: "dogefan@example.com" },
    { rank: 12, username: "CryptoHero", solanaBalance: 950, telegramUsername: "@CryptoHero", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=CryptoHero", email: "cryptohero@example.com" },
    { rank: 13, username: "BlockQueen", solanaBalance: 900, telegramUsername: "@BlockQueen", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=BlockQueen", email: "blockqueen@example.com" },
    { rank: 14, username: "EtherLord", solanaBalance: 850, telegramUsername: "@EtherLord", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=EtherLord", email: "etherlord@example.com" },
    { rank: 15, username: "CoinMaster", solanaBalance: 800, telegramUsername: "@CoinMaster", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=CoinMaster", email: "coinmaster@example.com" },
    { rank: 16, username: "SOLHustler", solanaBalance: 750, telegramUsername: "@SOLHustler", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=SOLHustler", email: "solhustler@example.com" },
    { rank: 17, username: "CryptoNinja", solanaBalance: 700, telegramUsername: "@CryptoNinja", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=CryptoNinja", email: "cryptoninja@example.com" },
    { rank: 18, username: "SOLMaster", solanaBalance: 650, telegramUsername: "@SOLMaster", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=SOLMaster", email: "solmaster@example.com" },
    { rank: 19, username: "BitQueen", solanaBalance: 600, telegramUsername: "@BitQueen", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=BitQueen", email: "bitqueen@example.com" },
    { rank: 20, username: "SOLHunter", solanaBalance: 550, telegramUsername: "@SOLHunter", avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=SOLHunter", email: "solhunter@example.com" },
  ];

  const getRankIcon = (rank: number) => {
    const rankIcons: Record<number, JSX.Element> = {
      1: <Trophy className="w-6 h-6 text-yellow-400" />,
      2: <Medal className="w-6 h-6 text-gray-400" />,
      3: <Award className="w-6 h-6 text-amber-600" />,
    };
    return rankIcons[rank] || <span className="w-6 h-6 flex items-center justify-center font-bold text-slate-400">{rank}</span>;
  };

  const partiallyHideEmail = (email: string) => {
    const [username, domain] = email.split("@");
    return `${"*".repeat(username.length - 2)}${username.slice(-2)}@${domain}`;
  };

  return (
    <>
      <style>
        {`
          .no-scrollbar::-webkit-scrollbar { display: none; }
          .no-scrollbar { -ms-overflow-style: none; scrollbar-width: none; }
        `}
      </style>

      <div className="space-y-8">
        <div className="bg-gradient-to-br from-purple-900/20 via-slate-900/20 to-blue-900/20 rounded-2xl border border-slate-800/50 p-8 shadow-xl">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-purple-500/10 rounded-lg">
              <Sparkles className="w-6 h-6 text-purple-500" />
            </div>
            <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-slate-300 bg-clip-text text-transparent">
              Welcome to DailySolly
            </h1>
          </div>
          <p className="text-slate-300 text-lg mb-6 max-w-2xl">
            Earn Solana rewards through easy tasks and connect with our community. Start your journey to daily rewards today!
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {features.map((feature, index) => (
              <div key={index} className="bg-slate-800/50 rounded-xl p-6 shadow-md backdrop-blur-sm">
                <div className={`${feature.bgColor} w-12 h-12 rounded-lg flex items-center justify-center mb-4`}>
                  <feature.icon className={`w-6 h-6 ${feature.color}`} />
                </div>
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-slate-400">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-center bg-slate-800/50 p-4 rounded-lg shadow-md mt-6">
            <div className="flex items-center gap-2 mb-2 sm:mb-0">
              <Sparkles className="w-5 h-5 text-purple-500" />
              <span className="text-slate-300 font-medium">Server Time:</span>
              <span className="text-slate-200">{serverTime.toLocaleString()}</span>
            </div>
            <div className="flex items-center gap-2">
              <Sparkles className="w-5 h-5 text-blue-500" />
              <span className="text-slate-300 font-medium">Local Time:</span>
              <span className="text-slate-200">{localTime.toLocaleString()}</span>
            </div>
          </div>
        </div>

        <NewsSection />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-1 bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6 shadow-lg">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold">Today's Tasks</h2>
              <div className="bg-slate-800/50 px-4 py-2 rounded-full text-sm">
                <span className="text-purple-400">{userStats.tasksCompleted}</span>
                <span className="text-slate-400"> / 4 Completed</span>
              </div>
            </div>
            <ul className="space-y-4">
              {dailyTasks.map((task, i) => (
                <li key={i} className="bg-slate-800/40 rounded-lg p-4 flex items-center justify-between transition hover:bg-slate-700">
                  <div className="flex items-center gap-3">
                    {task.icon}
                    <div>
                      <h3 className="font-semibold text-slate-300">{task.title}</h3>
                      <p className="text-slate-400 text-sm">{task.description}</p>
                    </div>
                  </div>
                  <button onClick={() => navigate('/tasks')} className="text-purple-400 hover:text-purple-500">
                    <ArrowRight className="w-5 h-5" />
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-slate-900/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-bold mb-2">Leaderboard</h2>
            <ul>
              {leaderboardData.slice(0, 8).map(entry => (
                <li key={entry.rank} className="flex justify-between text-slate-300 mb-2">
                  <span className="flex items-center gap-2">
                    <img src={entry.avatar} alt="avatar" className="w-8 h-8 rounded-full" />
                    {getRankIcon(entry.rank)}
                    <span className="font-medium">{entry.username}</span>
                  </span>
                  <span className="flex items-center gap-2">
                    <Coins className="w-5 h-5 text-purple-400" />
                    {entry.solanaBalance} SOL
                  </span>
                </li>
              ))}
            </ul>
            <button
              onClick={() => setShowLeaderboard(true)}
              className="mt-4 w-full bg-slate-800/50 hover:bg-slate-800 rounded-lg p-4 transition-transform transform hover:scale-105 text-slate-300 flex items-center justify-center"
            >
              View Full Leaderboard
              <ArrowRight className="w-5 h-5 text-yellow-400 ml-2" />
            </button>
          </div>

          <div className="md:col-span-1">
            <PromoteSection />
          </div>
        </div>

        {showLeaderboard && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-slate-900 p-8 rounded-lg shadow-lg w-[32rem] max-h-[70vh] overflow-hidden">
              <button
                onClick={() => setShowLeaderboard(false)}
                className="absolute top-4 right-4 text-slate-400 hover:text-slate-200"
              >
                <X className="w-6 h-6" />
              </button>
              <div className="flex items-center gap-3 mb-4">
                <Trophy className="w-6 h-6 text-yellow-400" />
                <h2 className="text-2xl font-bold text-slate-200">Leaderboard</h2>
              </div>
              <p className="text-slate-400 mb-4">Top players this week! Compete to be on top and earn more rewards.</p>
              <div className="space-y-3 overflow-y-auto no-scrollbar" style={{ maxHeight: '55vh' }}>
                {leaderboardData.slice(0, 20).map(entry => (
                  <li key={entry.rank} className="flex justify-between text-slate-300 mb-3 p-3 bg-slate-800/50 rounded-lg">
                    <div className="flex items-center gap-3">
                      <img src={entry.avatar} alt="avatar" className="w-8 h-8 rounded-full" />
                      {getRankIcon(entry.rank)}
                      <span className="font-semibold">{entry.rank}. {entry.username}</span>
                    </div>
                    <div className="text-right">
                      <span className="text-slate-400 block">{partiallyHideEmail(entry.email)}</span>
                      <span className="text-purple-400 font-semibold">{entry.solanaBalance} SOL</span>
                    </div>
                  </li>
                ))}
              </div>
            </div>
          </div>
        )}

        <StreakBrokenPopup
          isOpen={streakBroken}
          onClose={() => {}}
          onRestart={handleRestartStreak}
        />
      </div>
    </>
  );
}
