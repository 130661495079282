import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';

interface Notification {
  id: string;
  type: 'task' | 'withdraw' | 'report' | 'news' | 'bug' | 'info';
  title: string;
  message: string;
  timestamp: Date;
  read: boolean;
  action?: {
    label: string;
    onClick: () => void;
  };
}

export function useNotifications() {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!user) return;

    // Example notifications - replace with your actual data fetching logic
    setNotifications([
      {
        id: '1',
        type: 'task',
        title: 'Daily Tasks Available',
        message: 'New tasks are available! Complete them to earn SOL rewards.',
        timestamp: new Date(),
        read: false,
        action: {
          label: 'View Tasks',
          onClick: () => {/* Navigate to tasks */}
        }
      },
      {
        id: '2',
        type: 'withdraw',
        title: 'Withdrawal Processed',
        message: 'Your withdrawal of 0.5 SOL has been processed successfully.',
        timestamp: new Date(Date.now() - 3600000),
        read: false
      },
      {
        id: '3',
        type: 'news',
        title: 'New Feature Released',
        message: 'Check out our new referral system! Earn more by inviting friends.',
        timestamp: new Date(Date.now() - 86400000),
        read: true
      },
      {
        id: '4',
        type: 'report',
        title: 'Weekly Earnings Report',
        message: 'You earned 0.25 SOL this week! Keep up the good work.',
        timestamp: new Date(Date.now() - 172800000),
        read: true
      }
    ]);
  }, [user]);

  useEffect(() => {
    setUnreadCount(notifications.filter(n => !n.read).length);
  }, [notifications]);

  const markAsRead = (id: string) => {
    setNotifications(prev =>
      prev.map(notification =>
        notification.id === id
          ? { ...notification, read: true }
          : notification
      )
    );
  };

  const markAllAsRead = () => {
    setNotifications(prev =>
      prev.map(notification => ({ ...notification, read: true }))
    );
  };

  return {
    notifications,
    unreadCount,
    markAsRead,
    markAllAsRead
  };
}