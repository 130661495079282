import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { streakService } from '../services/streak';

export function useStreak() {
  const { user } = useAuth();
  const [streakBroken, setStreakBroken] = useState(false);
  const [previousStreak, setPreviousStreak] = useState(0);

  useEffect(() => {
    if (!user) return;

    const checkStreak = async () => {
      const { broken, days } = await streakService.checkStreak(user.uid);
      if (broken && days > 0) {
        setStreakBroken(true);
        setPreviousStreak(days);
      }
    };

    checkStreak();
  }, [user]);

  const handleRestartStreak = async () => {
    if (!user) return;
    await streakService.resetStreak(user.uid);
    setStreakBroken(false);
  };

  return {
    streakBroken,
    previousStreak,
    handleRestartStreak
  };
}