import React, { useState, useEffect } from 'react';
import { Share2, MessageCircle, Copy, Check, ExternalLink, Award, UserPlus, TrendingUp } from 'lucide-react';
import { useApp } from '../contexts/AppContext';  // Ensure you have this context
import toast from 'react-hot-toast';

// Function to generate a random referral code
const generateReferralCode = () => {
  const randomNumber = Math.floor(Math.random() * 100000); // Generate a random number between 0 and 99999
  return `SOLLY${randomNumber}`; // Create a unique referral code
};

export function ReferralCard() {
  const { userStats } = useApp();  // Ensure userStats are coming from context
  const [copied, setCopied] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    // Generate a unique referral code and create the referral link
    const referralCode = generateReferralCode(); // Generate unique referral code
    const link = `${window.location.origin}?ref=${referralCode}`; // Create referral link
    setReferralLink(link); // Set the referral link
  }, []); // Run once when the component mounts

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopied(true);
      toast.success('Referral link copied!');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const handleShare = () => {
    setShowSharePopup(true);
  };

  // Ensure that userStats are available
  if (!userStats) {
    return <div>Loading...</div>;
  }

  // Safeguard to check if leaderboard exists and has data
  const leaderboard = userStats.leaderboard || [];
  const totalInvites = userStats.totalInvites || 0;  // Ensure totalInvites exists

  // Calculating referral conversion rate
  const referralConversionRate = totalInvites > 0 ? ((userStats.referralCount / totalInvites) * 100).toFixed(2) : 0;

  return (
    <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6 mb-6">
      <h2 className="text-xl font-bold mb-4 text-white">Refer & Earn with Sollys</h2>
      <p className="text-slate-300 mb-4">
        Share your referral link and earn Sollys rewards when friends sign up using your link! Get rewarded for every successful referral.
      </p>

      {/* Referral Link Section */}
      <div className="bg-slate-800/50 rounded-lg p-4 mb-6">
        <div className="flex items-center gap-3 mb-4">
          <Share2 className="w-6 h-6 text-purple-400" />
          <h3 className="text-lg font-bold text-white">Your Referral Link</h3>
        </div>
        <p className="text-sm text-slate-400 mb-4">Share your unique referral link with friends and earn rewards when they join!</p>
        <div className="flex justify-between items-center">
          <div className="w-3/4">
            <p className="text-lg font-mono font-bold text-white">{referralLink}</p> {/* Show full link */}
          </div>
          <div className="flex gap-2">
            <button 
              onClick={handleCopy}
              className="bg-purple-500/20 hover:bg-purple-500/30 p-2 rounded-lg transition-colors"
              title={copied ? 'Copied!' : 'Copy link'}
            >
              {copied ? <Check className="w-5 h-5 text-green-500" /> : <Copy className="w-5 h-5 text-purple-400" />}
            </button>
            <button 
              onClick={handleShare}
              className="bg-purple-500 hover:bg-purple-600 p-2 rounded-lg transition-colors"
              title="Share"
            >
              <Share2 className="w-5 h-5 text-white" />
            </button>
          </div>
        </div>
      </div>

      {/* Referral Stats Section */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="bg-slate-800/30 rounded-lg p-4">
          <p className="text-sm text-slate-400">Total Referrals</p>
          <p className="text-2xl font-bold text-white">{userStats.referralCount}</p>
        </div>
        <div className="bg-slate-800/30 rounded-lg p-4">
          <p className="text-sm text-slate-400">Total Earned</p>
          <p className="text-2xl font-bold text-white">{userStats.referralEarnings} SOLLY</p>
        </div>
        <div className="bg-slate-800/30 rounded-lg p-4">
          <p className="text-sm text-slate-400">Referral Conversion Rate</p>
          <p className="text-2xl font-bold text-white">{referralConversionRate}%</p>
        </div>
      </div>

      {/* More Advanced Stats */}
      <div className="bg-slate-800/30 rounded-lg p-4 mb-6">
        <h3 className="text-lg font-bold text-white">Referral Leaderboard</h3>
        <div className="space-y-3">
          {leaderboard.length > 0 ? leaderboard.map((entry, index) => (
            <div key={index} className="flex justify-between items-center text-white">
              <p className="text-sm">{entry.name}</p>
              <p className="font-bold">{entry.referrals} Referrals</p>
            </div>
          )) : (
            <p className="text-sm text-slate-400">No leaderboard data available</p>
          )}
        </div>
      </div>

      {/* Referral Program Benefits */}
      <div className="bg-slate-800/30 rounded-lg p-4 mb-6">
        <h3 className="text-lg font-bold text-white">Referral Program Benefits</h3>
        <p className="text-sm text-slate-400 mb-4">
          Earn rewards with every referral! Here are the perks of participating in the Sollys referral program:
        </p>
        <div className="space-y-3">
          <div className="flex items-center gap-3">
            <Award className="w-6 h-6 text-yellow-400" />
            <p className="text-white">Get exclusive rewards for each successful referral.</p>
          </div>
          <div className="flex items-center gap-3">
            <UserPlus className="w-6 h-6 text-green-400" />
            <p className="text-white">Your friends earn rewards too when they sign up using your link!</p>
          </div>
          <div className="flex items-center gap-3">
            <TrendingUp className="w-6 h-6 text-blue-400" />
            <p className="text-white">Track your referral progress and see how you rank on the leaderboard.</p>
          </div>
        </div>
      </div>

      {showSharePopup && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-slate-900 rounded-2xl w-full max-w-md border border-slate-800 p-6">
            <h3 className="text-xl font-bold mb-4 text-white">Share Your Referral Link</h3>
            <div className="space-y-3">
              <a
                href={`https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent('Join Sollys and earn rewards!')}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 bg-slate-800/50 hover:bg-slate-800 p-4 rounded-lg transition-colors w-full"
              >
                <MessageCircle className="w-5 h-5 text-blue-400" />
                <span className="text-white">Share on Telegram</span>
                <ExternalLink className="w-4 h-4 ml-auto text-white" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent('Join Sollys and earn rewards!')}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 bg-slate-800/50 hover:bg-slate-800 p-4 rounded-lg transition-colors w-full"
              >
                <img src="https://upload.wikimedia.org/wikipedia/en/6/60/Twitter_bird_logo_2012.svg" alt="Twitter" className="w-5 h-5" />
                <span className="text-white">Share on Twitter</span>
                <ExternalLink className="w-4 h-4 ml-auto text-white" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 bg-slate-800/50 hover:bg-slate-800 p-4 rounded-lg transition-colors w-full"
              >
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" className="w-5 h-5" />
                <span className="text-white">Share on Facebook</span>
                <ExternalLink className="w-4 h-4 ml-auto text-white" />
              </a>
            </div>
            <button
              onClick={() => setShowSharePopup(false)}
              className="w-full mt-4 py-3 bg-slate-800/50 hover:bg-slate-800 rounded-lg transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
