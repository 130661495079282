import React from 'react';
import { Menu } from './Menu';
import { Header } from './Header';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen bg-slate-950 text-white">
      <Header />
      <main className="container mx-auto px-4 pb-20 pt-4">
        {children}
      </main>
      <Menu />
    </div>
  );
}