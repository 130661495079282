import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { analyticsService } from './analytics';
import { generateReferralCode } from '../utils/referral';

export const authService = {
  async register(email: string, password: string) {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Create user profile in Firestore with initial stats
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      referralCode: generateReferralCode(),
      balance: 0,
      daysCompleted: 0,
      totalEarned: 0,
      referralCount: 0,
      referralEarnings: 0,
      tasksCompleted: 0,
      createdAt: new Date(),
      lastActive: new Date(),
    });

    // Create initial online status
    await setDoc(doc(db, 'onlineStatus', user.uid), {
      status: 'online',
      lastActive: new Date(),
    });

    // Track registration
    await analyticsService.trackUserActivity(user.uid, 'register');

    return user;
  },

  async login(email: string, password: string) {
    const result = await signInWithEmailAndPassword(auth, email, password);
    
    // Update online status on login
    if (result.user) {
      await setDoc(doc(db, 'onlineStatus', result.user.uid), {
        status: 'online',
        lastActive: new Date(),
      }, { merge: true });
    }
    
    return result;
  },

  async logout() {
    // Update online status before logging out
    if (auth.currentUser) {
      try {
        await setDoc(doc(db, 'onlineStatus', auth.currentUser.uid), {
          status: 'offline',
          lastActive: new Date(),
        }, { merge: true });
      } catch (error) {
        console.error('Error updating online status:', error);
      }
    }
    
    return signOut(auth);
  }
};