import React, { useState } from 'react';
import { Coins, TrendingUp, Bell, ExternalLink } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { BalanceStatsPopup } from './BalanceStatsPopup';
import { NotificationsPopup } from './NotificationsPopup';
import { useNotifications } from '../hooks/useNotifications';

export function Header() {
  const { userStats } = useApp();
  const [showStats, setShowStats] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { notifications, unreadCount, markAsRead, markAllAsRead } = useNotifications();
  
  return (
    <div className="bg-gradient-to-b from-slate-900/80 to-slate-900/60 backdrop-blur-lg border-b border-slate-800/50 sticky top-0 z-40">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          {/* Logo & Brand */}
          <div className="flex items-center gap-3">
            <div className="bg-gradient-to-br from-purple-500/20 to-blue-500/20 p-2 rounded-xl">
              <Coins className="w-6 h-6 text-purple-400" />
            </div>
            <div>
              <h1 className="text-xl font-bold bg-gradient-to-r from-white to-slate-300 bg-clip-text text-transparent">
                DailySolly
              </h1>
              <p className="text-xs text-slate-400">Earn SOL Daily</p>
            </div>
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-3">
            {/* Notifications */}
            <button 
              onClick={() => setShowNotifications(true)}
              className="relative p-2 text-slate-400 hover:text-white hover:bg-slate-800/50 rounded-lg transition-colors"
            >
              <Bell className="w-5 h-5" />
              {unreadCount > 0 && (
                <span className="absolute top-1 right-1 w-2 h-2 bg-purple-500 rounded-full" />
              )}
            </button>

            {/* Balance Button */}
            <button
              onClick={() => setShowStats(true)}
              className="flex items-center gap-3 bg-slate-800/50 hover:bg-slate-800 rounded-lg pl-4 pr-3 py-2 transition-all group hover:ring-1 hover:ring-purple-500/50"
            >
              <div>
                <div className="flex items-center gap-1.5">
                  <span className="text-sm text-slate-400">Balance</span>
                  <ExternalLink className="w-3.5 h-3.5 text-slate-500 group-hover:text-purple-400 transition-colors" />
                </div>
                <div className="font-mono font-bold text-white">
                  {userStats.balance.toFixed(4)} SOL
                </div>
              </div>
              <div className="pl-2 border-l border-slate-700">
                <TrendingUp className="w-4 h-4 text-green-400 group-hover:scale-110 transition-transform" />
              </div>
            </button>
          </div>
        </div>
      </div>

      <BalanceStatsPopup
        isOpen={showStats}
        onClose={() => setShowStats(false)}
        stats={{
          balance: userStats.balance,
          totalEarned: userStats.totalEarned,
          pendingRewards: 0.0025,
          lastWithdrawal: '2024-03-20',
          referralEarnings: userStats.referralEarnings,
          taskEarnings: userStats.totalEarned - userStats.referralEarnings,
          solPrice: 124.53,
        }}
      />

      <NotificationsPopup
        isOpen={showNotifications}
        onClose={() => setShowNotifications(false)}
        notifications={notifications}
        onMarkAsRead={markAsRead}
        onMarkAllAsRead={markAllAsRead}
      />
    </div>
  );
}