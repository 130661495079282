export const dailyTasks = [
  {
    id: 1,
    title: "Visit DailySolly Website",
    description: "Visit our official website and explore the platform for at least 2 minutes. Take a screenshot as proof.",
    reward: 0.001,
    type: "visit",
    url: "https://dailysolly.com",
    proofType: "screenshot",
    proofInstructions: "Take a screenshot showing the website with your wallet address visible"
  },
  {
    id: 2,
    title: "Join Telegram Community",
    description: "Join our Telegram group, introduce yourself and engage with the community. Share your username as proof.",
    reward: 0.001,
    type: "social",
    url: "https://t.me/dailysolly",
    proofType: "username",
    proofInstructions: "Share your Telegram username and a screenshot of your introduction message"
  },
  {
    id: 3,
    title: "Share on Twitter",
    description: "Create a tweet about DailySolly and tag us. Must include #DailySolly hashtag.",
    reward: 0.001,
    type: "social",
    url: "https://twitter.com/intent/tweet?text=I'm earning Solana rewards with @DailySolly! Join me and start earning today! %23DailySolly",
    proofType: "link",
    proofInstructions: "Share the link to your tweet"
  }
];