import React from 'react';
import { ExternalLink, CheckCircle2, Upload } from 'lucide-react';
import { Task } from '../types';

interface TaskCardProps {
  task: Task;
  onSubmitProof: () => void;
}

export function TaskCard({ task, onSubmitProof }: TaskCardProps) {
  return (
    <div className="bg-slate-800/50 rounded-lg p-4 hover:bg-slate-800 transition-colors">
      <div className="flex items-center justify-between">
        <div>
          <h4 className="font-medium mb-1">{task.title}</h4>
          <p className="text-sm text-slate-400">{task.description}</p>
          <div className="flex items-center gap-3 mt-2">
            <span className="text-purple-400 text-sm">Reward: {task.reward} SOL</span>
            {task.url && (
              <a
                href={task.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300 text-sm flex items-center gap-1"
              >
                Visit Link <ExternalLink className="w-3 h-3" />
              </a>
            )}
          </div>
        </div>
        {task.completed ? (
          <div className="flex items-center gap-2 text-green-500">
            <CheckCircle2 className="w-6 h-6" />
            <span className="text-sm">Completed</span>
          </div>
        ) : (
          <button
            onClick={onSubmitProof}
            className="bg-purple-500/10 hover:bg-purple-500/20 text-purple-400 px-4 py-2 rounded-lg flex items-center gap-2 transition-colors"
          >
            <Upload className="w-4 h-4" />
            Submit Proof
          </button>
        )}
      </div>
    </div>
  );
}