import React, { useState, useEffect } from 'react';
import {
  Trophy,
  Users,
  Coins,
  CheckSquare,
  Shield,
  Users2,
  Pencil,
  LogOut,
  Mail,
  Link,
  PieChart,
  CheckCircle,
  Gift,
  Clock,
  Info,
  X,
  Share2,
  Star,
  Settings,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import { NewsSection } from '../components/NewsSection';
import { PromoteSection } from '../components/PromoteSection';
import { useAuth } from '../hooks/useAuth';
import { EditProfilePopup } from '../components/EditProfilePopup';
import { ProfileBadges } from '../components/ProfileBadges';
import { AdminLoginPopup } from '../components/AdminLoginPopup';
import { InactivityPopup } from '../components/InactivityPopup';
import { auth } from '../firebase';
import toast from 'react-hot-toast';
import axios from 'axios';



// Type Definitions for GiveawayPopup Props
interface GiveawayPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onEnter: () => void;
  entries: number;
  prize: string;
  rules: string[];
  pastWinners: string[];
}

// GiveawayPopup Component
const GiveawayPopup: React.FC<GiveawayPopupProps> = ({
  isOpen,
  onClose,
  onEnter,
  entries,
  prize,
  rules,
  pastWinners,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-slate-900 rounded-xl p-6 w-96 relative max-h-screen overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-slate-400 hover:text-slate-300"
          title="Close"
        >
          <X className="w-5 h-5" />
        </button>
        <div className="flex flex-col items-center mb-4">
          <Gift className="w-12 h-12 text-purple-500 mb-2" />
          <h3 className="text-xl font-semibold">Enter Giveaway</h3>
        </div>
        <p className="text-slate-400 mb-2 text-center">
          Join our giveaway for a chance to win <span className="font-bold">{prize}</span>!
        </p>
        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-2 flex items-center">
            <Info className="w-5 h-5 mr-2 text-blue-400" />
            Giveaway Rules
          </h4>
          <ul className="list-disc list-inside text-slate-400">
            {rules.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
        </div>
        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-2 flex items-center">
            <Share2 className="w-5 h-5 mr-2 text-green-400" />
            How to Enter
          </h4>
          <ul className="list-disc list-inside text-slate-400">
            <li>Complete 5 tasks.</li>
            <li>Share your referral link on social media.</li>
            <li>Refer at least one friend.</li>
          </ul>
        </div>
        <p className="text-slate-400 text-center mb-4">
          <span className="font-semibold">Current Entries:</span> {entries}
        </p>
        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-2 flex items-center">
            <Star className="w-5 h-5 mr-2 text-yellow-400" />
            Past Winners
          </h4>
          {pastWinners.length > 0 ? (
            <ul className="list-disc list-inside text-slate-400">
              {pastWinners.map((winner, index) => (
                <li key={index}>{winner}</li>
              ))}
            </ul>
          ) : (
            <p className="text-slate-400">No winners yet.</p>
          )}
        </div>
        <div className="flex justify-between mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-slate-800 text-slate-400 rounded-lg hover:bg-slate-700 transition-colors flex items-center gap-2"
          >
            <X className="w-5 h-5" />
            Cancel
          </button>
          <button
            onClick={onEnter}
            className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2"
          >
            <CheckCircle className="w-5 h-5" />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

// GiveawaySection Component
interface GiveawaySectionProps {
  onOpenPopup: () => void;
  giveawayEntries: number;
  prize: string;
  rules: string[];
  pastWinners: string[];
}

const GiveawaySection: React.FC<GiveawaySectionProps> = ({
  onOpenPopup,
  giveawayEntries,
  prize,
  rules,
  pastWinners,
}) => {
  const [giveawayEndTime] = useState<number>(new Date().getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days from now
  const [timeLeft, setTimeLeft] = useState<string>('');

  // Timer for Giveaway
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = giveawayEndTime - now;

      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft('Giveaway Ended');
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [giveawayEndTime]);

  return (
    <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
      <div className="flex items-center mb-4">
        <Gift className="w-6 h-6 text-purple-500 mr-2" />
        <h3 className="text-lg font-semibold">Giveaway</h3>
      </div>
      <p className="text-slate-400 mb-2">
        Participate in our weekly giveaway for a chance to win exclusive rewards! Hurry up, the giveaway ends in:
      </p>
      <div className="flex items-center mb-4">
        <Clock className="w-5 h-5 text-yellow-400 mr-2" />
        <p className="text-xl font-bold text-green-500">{timeLeft}</p>
      </div>
      <button
        onClick={onOpenPopup}
        className="w-full flex items-center justify-center px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors"
      >
        <Gift className="w-5 h-5 mr-2" />
        Enter Giveaway
      </button>
      <p className="text-slate-400 mt-2 text-center">
        Total Entries: {giveawayEntries}
      </p>
    </div>
  );
};

// Activity Feed Component
const ActivityFeed: React.FC = () => {
  const activities = [
    { description: 'Completed 5 tasks', date: '2024-11-10', icon: <CheckSquare className="w-5 h-5 text-green-500" /> },
    { description: 'Referred a friend', date: '2024-11-09', icon: <Users className="w-5 h-5 text-blue-500" /> },
    { description: 'Logged in', date: '2024-11-08', icon: <LogOut className="w-5 h-5 text-red-500" /> },
  ];

  return (
    <div className="bg-slate-900/50 rounded-xl border border-slate-800 p-6">
      <h3 className="text-lg font-semibold flex items-center mb-4">
        <Clock className="w-5 h-5 text-blue-500 mr-2" />
        Activity Feed
      </h3>
      <ul className="space-y-2">
        {activities.map((activity, index) => (
          <li key={index} className="text-slate-400 text-sm flex items-center">
            {activity.icon}
            <p className="ml-2">{activity.description}</p>
            <p className="text-xs text-slate-500 ml-2">{activity.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Inactivity Feed Component
const InactivityFeed: React.FC = () => {
  const [inactivityCount, setInactivityCount] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(120); // 2 minutes countdown (120 seconds)

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(prev => prev - 1); // Decrease time left by 1 second every 1 second
    }, 1000);

    // Log out after the countdown reaches 0
    if (timeLeft === 0) {
      toast.success('You have been logged out due to inactivity');
      auth.signOut().then(() => {
        navigate('/login', { replace: true }); // Redirect to login page
      });
    }

    return () => clearTimeout(timer); // Cleanup the timer on unmount or when the component re-renders
  }, [timeLeft, navigate]);

  useEffect(() => {
    const resetInactivity = () => {
      setTimeLeft(120); // Reset the timer back to 2 minutes
    };

    // Add event listeners for mouse and keyboard activity
    window.addEventListener('mousemove', resetInactivity);
    window.addEventListener('keydown', resetInactivity);

    return () => {
      window.removeEventListener('mousemove', resetInactivity);
      window.removeEventListener('keydown', resetInactivity);
    };
  }, []);

  return (
    <div className="bg-slate-900/50 rounded-xl border border-slate-800 p-6">
      <h3 className="text-lg font-semibold flex items-center mb-4">
        <Clock className="w-5 h-5 text-red-500 mr-2" />
        Inactivity Feed
      </h3>
      <p className="text-slate-400">
        You have been logged out <span className="font-bold">{inactivityCount}</span> times due to inactivity.
      </p>
      <p className="text-slate-400">
        You will be logged out automatically in <span className="font-bold">{timeLeft}s</span>.
      </p>
    </div>
  );
};

// Settings Panel Component
const SettingsPanel: React.FC = () => {
  const [notifications, setNotifications] = useState<boolean>(true);

  const toggleNotifications = () => {
    setNotifications(!notifications);
    toast.success(`Notifications ${notifications ? 'disabled' : 'enabled'}`);
  };

  return (
    <div className="bg-slate-900/50 rounded-xl border border-slate-800 p-6">
      <h3 className="text-lg font-semibold flex items-center mb-4">
        <Settings className="w-5 h-5 text-purple-500 mr-2" />
        Settings
      </h3>
      <div className="flex items-center justify-between">
        <p className="text-slate-400 text-sm">Enable Notifications</p>
        <button
          onClick={toggleNotifications}
          className={`px-4 py-2 rounded-lg transition-colors ${notifications ? 'bg-green-500' : 'bg-slate-800'}`}
        >
          {notifications ? 'Enabled' : 'Disabled'}
        </button>
      </div>
    </div>
  );
};

// Main ProfilePage Component
export function ProfilePage() {
  const { userStats } = useApp();
  const { user, isAdmin } = useAuth(); // Get current user and admin status
  const navigate = useNavigate();
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showPromoteSection, setShowPromoteSection] = useState<boolean>(false);
  const [showAdminLogin, setShowAdminLogin] = useState<boolean>(false);
  const [loginCount, setLoginCount] = useState<number>(0);
  const [referralLink, setReferralLink] = useState<string>('');
  const [showGiveawayPopup, setShowGiveawayPopup] = useState<boolean>(false);
  const [giveawayEntries, setGiveawayEntries] = useState<number>(0);
  const [pastWinners, setPastWinners] = useState<string[]>(['Alice - 1 SOL', 'Bob - 0.5 SOL']); // Example data
  const [showInactivityPopup, setShowInactivityPopup] = useState<boolean>(false); // NEW: State for inactivity popup
  const [logoutTime, setLogoutTime] = useState<Date | null>(null); // NEW: State for logout time

  // Giveaway Details
  const giveawayPrize: string = '1 SOL Token';
  const giveawayRules: string[] = [
    'Must complete at least 5 tasks.',
    'Share your referral link on social media.',
    'Refer at least one friend.',
  ];

  // Generate a unique referral link for the user
  useEffect(() => {
    if (user) {
      setReferralLink(`${window.location.origin}/referral/${user.uid}`);
    }
  }, [user]);

  const userBadges: string[] = [
    'premium',
    'early_access',
    'verified',
    ...(isAdmin ? ['staff', 'moderator'] : []),
  ];

  useEffect(() => {
    if (user) {
      // Fetch the current login count from the server
      axios
        .get('/api/getLoginCount', { params: { email: user.email } })
        .then((response) => {
          setLoginCount(response.data.loginCount || 0);
        })
        .catch((error) => {
          console.error('Error fetching login count:', error.response || error.message || error);
          toast.error('Failed to fetch login count');
        });
    }
  }, [user]);

  // NEW: Inactivity timer state
  const [timeLeft, setTimeLeft] = useState<number>(120); // Time in seconds

  // NEW: Effect to handle inactivity timer
  useEffect(() => {
    const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);

    if (timeLeft === 0) {
      setShowInactivityPopup(true);
      auth.signOut().then(() => {
        setLogoutTime(new Date());
        toast.success('Logged out due to inactivity');
        // Do not navigate away yet to allow the popup to display
      });
    }

    return () => clearInterval(timer);
  }, [timeLeft]);

  // NEW: Reset inactivity timer on user interaction
  useEffect(() => {
    const resetInactivity = () => setTimeLeft(120);

    window.addEventListener('mousemove', resetInactivity);
    window.addEventListener('keydown', resetInactivity);

    return () => {
      window.removeEventListener('mousemove', resetInactivity);
      window.removeEventListener('keydown', resetInactivity);
    };
  }, []);

  // NEW: Handle closure of inactivity popup
  const handleInactivityPopupClose = () => {
    setShowInactivityPopup(false);
    navigate('/login', { replace: true }); // Navigate to login page
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      toast.success('Logged out successfully');
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to logout');
    }
  };

  const handleAdminAccess = () => {
    if (isAdmin) {
      // If user is an admin, navigate to the AdminPanel
      navigate('/admin');
    } else {
      // If the user is not an admin, show the admin login popup
      setShowAdminLogin(true);
    }
  };

  const handleAdminLoginSuccess = () => {
    setShowAdminLogin(false);
    navigate('/admin'); // Redirect to admin panel after successful login
  };

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success('Referral link copied to clipboard');
  };

  const handleEnterGiveaway = () => {
    // Simulate entering the giveaway
    setGiveawayEntries((prev) => prev + 1);
    toast.success('You have entered the giveaway!');
    setShowGiveawayPopup(false);
  };

  const stats: Array<{
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    label: string;
    value: string | number;
    color: string;
  }> = [
    { icon: Trophy, label: 'Days Streak', value: `${userStats.daysCompleted}/7`, color: 'text-yellow-500' },
    { icon: Users, label: 'Referrals', value: userStats.referralCount, color: 'text-blue-500' },
    { icon: Coins, label: 'Total Earned', value: `${userStats.totalEarned} SOL`, color: 'text-purple-500' },
    { icon: CheckSquare, label: 'Tasks Completed', value: userStats.tasksCompleted, color: 'text-green-500' },
    { icon: Users2, label: 'Active Users', value: userStats.activeUsers, color: 'text-pink-500' },
    { icon: Users2, label: 'Login Count', value: loginCount, color: 'text-orange-500' },
  ];

  const weeklyGoals: Array<{ task: string; progress: number }> = [
    { task: 'Complete 5 tasks', progress: userStats.tasksCompleted / 5 },
    { task: 'Earn 0.1 SOL', progress: userStats.totalEarned >= 0.1 ? 1 : userStats.totalEarned / 0.1 },
    { task: 'Refer a friend', progress: userStats.referralCount > 0 ? 1 : 0 },
  ];

  return (
    <div className="space-y-6">
      {/* Profile Information */}
      <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <div className="flex items-center gap-3">
              <h2 className="text-xl font-bold">Profile</h2>
              <button
                onClick={handleAdminAccess}
                className="p-1.5 bg-purple-500/20 text-purple-400 rounded-lg hover:bg-purple-500/30 transition-colors"
                title="Admin Panel"
              >
                <Shield className="w-5 h-5" />
              </button>
            </div>
            <div className="flex items-center gap-2 mt-2">
              <Mail className="w-4 h-4 text-slate-400" />
              <p className="text-slate-300">{user?.email}</p>
              <button
                onClick={() => setShowEditProfile(true)}
                className="p-1.5 bg-slate-800/50 rounded-lg hover:bg-slate-800 transition-colors"
                title="Edit Profile"
              >
                <Pencil className="w-4 h-4 text-slate-400" />
              </button>
            </div>
            <p className="text-sm text-slate-400 mt-1">
              Member since {new Date(user?.metadata?.creationTime || '').toLocaleDateString()}
            </p>
            <ProfileBadges badges={userBadges} />
          </div>
          <button
            onClick={handleLogout}
            className="p-2 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors flex items-center gap-2"
            title="Logout"
          >
            <LogOut className="w-5 h-5" />
          </button>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {stats.map(({ icon: Icon, label, value, color }) => (
            <div key={label} className="bg-slate-800/30 rounded-lg p-4 flex items-center">
              <Icon className={`w-6 h-6 ${color} mr-3`} />
              <div>
                <p className="text-sm text-slate-400">{label}</p>
                <p className="text-xl font-bold text-slate-200">{value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Weekly Goals Section */}
      <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
        <div className="flex items-center mb-4">
          <CheckSquare className="w-6 h-6 text-green-500 mr-2" />
          <h3 className="text-lg font-semibold">Weekly Goals</h3>
        </div>
        <div className="space-y-4">
          {weeklyGoals.map(({ task, progress }) => (
            <div key={task} className="flex items-center">
              <p className="text-slate-400 flex-1">{task}</p>
              <div className="w-full bg-slate-800 h-2 rounded-full overflow-hidden mx-4">
                <div
                  style={{ width: `${progress * 100}%` }}
                  className={`h-full ${progress === 1 ? 'bg-green-500' : 'bg-blue-500'}`}
                ></div>
              </div>
              {progress === 1 && <CheckCircle className="w-5 h-5 text-green-500" />}
            </div>
          ))}
        </div>
      </div>

      {/* Referral Link, Giveaway, Promote, Activity Feed, Settings, and Inactivity Feed Sections */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Giveaway Section */}
        <GiveawaySection
          onOpenPopup={() => setShowGiveawayPopup(true)}
          giveawayEntries={giveawayEntries}
          prize={giveawayPrize}
          rules={giveawayRules}
          pastWinners={pastWinners}
        />

        {/* Referral Link Section */}
        <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
          <div className="flex items-center mb-4">
            <Link className="w-6 h-6 text-purple-500 mr-2" />
            <h3 className="text-lg font-semibold">Referral Link</h3>
          </div>
          <p className="text-slate-400 mb-2">Invite friends and earn rewards</p>
          <div className="flex items-center">
            <input
              type="text"
              value={referralLink}
              readOnly
              className="w-full bg-slate-800 p-2 rounded-lg text-slate-400"
            />
            <button
              onClick={copyReferralLink}
              className="p-2 bg-purple-500/20 text-purple-400 rounded-lg hover:bg-purple-500/30 ml-2"
              title="Copy Link"
            >
              <Link className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Inactivity Feed */}
        <InactivityFeed />

        {/* Promote Section */}
        <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
          <button
            onClick={() => setShowPromoteSection(!showPromoteSection)}
            className="w-full text-left"
          >
            <div className="flex items-center mb-4">
              <PieChart className="w-6 h-6 text-green-500 mr-2" />
              <h3 className="text-lg font-semibold">Promote Your Content</h3>
            </div>
            <p className="text-slate-400 mt-2">Click to expand promotion options</p>
          </button>
          {showPromoteSection && <PromoteSection />}
        </div>

        {/* Activity Feed */}
        <ActivityFeed />

        {/* Settings Panel */}
        <SettingsPanel />
      </div>

      {/* Giveaway Popup */}
      <GiveawayPopup
        isOpen={showGiveawayPopup}
        onClose={() => setShowGiveawayPopup(false)}
        onEnter={handleEnterGiveaway}
        entries={giveawayEntries}
        prize={giveawayPrize}
        rules={giveawayRules}
        pastWinners={pastWinners}
      />

      {/* News Section */}
      <NewsSection />

      {/* Edit Profile Popup */}
      {showEditProfile && (
        <EditProfilePopup
          isOpen={showEditProfile}
          onClose={() => setShowEditProfile(false)}
          currentEmail={user?.email || ''}
        />
      )}

      {/* Admin Login Popup */}
      {showAdminLogin && (
        <AdminLoginPopup
          isOpen={showAdminLogin}
          onClose={() => setShowAdminLogin(false)}
          onSuccess={handleAdminLoginSuccess}
        />
      )}

      {/* NEW: Inactivity Popup */}
      <InactivityPopup
        isOpen={showInactivityPopup}
        onClose={handleInactivityPopupClose}
        logoutTime={logoutTime}
      />
    </div>
  );
}