import React from 'react';
import { X, Wallet, Clock, TrendingUp, ArrowUpRight, ArrowDownRight, DollarSign, Users, CheckSquare } from 'lucide-react';

interface BalanceStatsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  stats: {
    balance: number;
    totalEarned: number;
    pendingRewards: number;
    lastWithdrawal: string;
    referralEarnings: number;
    taskEarnings: number;
    solPrice: number;
  };
}

export function BalanceStatsPopup({ isOpen, onClose, stats }: BalanceStatsPopupProps) {
  if (!isOpen) return null;

  const formatUSD = (sol: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(sol * stats.solPrice);
  };

  const formatSOL = (sol: number) => {
    return `${sol.toFixed(4)} SOL`;
  };

  return (
    <div 
      className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-start justify-center z-50 p-4"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div 
        className="bg-slate-900 rounded-2xl w-full max-w-md border border-slate-800 mt-20 animate-in zoom-in-95 duration-200"
        style={{ maxHeight: 'calc(100vh - 8rem)', overflowY: 'auto' }}
      >
        <div className="sticky top-0 bg-slate-900 z-10 p-6 border-b border-slate-800">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-xl font-bold">Balance Details</h3>
              <p className="text-slate-400 text-sm">Current SOL price: {formatUSD(1)}</p>
            </div>
            <button 
              onClick={onClose} 
              className="text-slate-400 hover:text-white p-1 hover:bg-slate-800/50 rounded-lg transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="p-6 space-y-4">
          {/* Current Balance */}
          <div className="bg-gradient-to-br from-purple-500/20 via-slate-800/20 to-blue-500/20 rounded-xl p-4 border border-slate-800 transform hover:scale-[1.02] transition-transform">
            <div className="flex items-center gap-3 mb-2">
              <Wallet className="w-5 h-5 text-purple-400" />
              <span className="text-slate-300">Current Balance</span>
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-bold">{formatSOL(stats.balance)}</span>
              <span className="text-slate-400 text-sm">{formatUSD(stats.balance)}</span>
            </div>
          </div>

          {/* Quick Stats Grid */}
          <div className="grid grid-cols-2 gap-3">
            <div className="bg-slate-800/50 rounded-lg p-3 hover:bg-slate-800/70 transition-colors">
              <div className="flex items-center gap-2 mb-1">
                <TrendingUp className="w-4 h-4 text-green-400" />
                <span className="text-sm text-slate-300">Total Earned</span>
              </div>
              <div className="font-mono">
                <div className="font-bold">{formatSOL(stats.totalEarned)}</div>
                <div className="text-sm text-slate-400">{formatUSD(stats.totalEarned)}</div>
              </div>
            </div>

            <div className="bg-slate-800/50 rounded-lg p-3 hover:bg-slate-800/70 transition-colors">
              <div className="flex items-center gap-2 mb-1">
                <Clock className="w-4 h-4 text-yellow-400" />
                <span className="text-sm text-slate-300">Pending</span>
              </div>
              <div className="font-mono">
                <div className="font-bold">{formatSOL(stats.pendingRewards)}</div>
                <div className="text-sm text-slate-400">{formatUSD(stats.pendingRewards)}</div>
              </div>
            </div>
          </div>

          {/* Earnings Breakdown */}
          <div className="bg-slate-800/50 rounded-lg p-4 hover:bg-slate-800/70 transition-colors">
            <h4 className="text-sm font-medium text-slate-300 mb-3">Earnings Breakdown</h4>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Users className="w-4 h-4 text-blue-400" />
                  <span className="text-sm text-slate-300">Referrals</span>
                </div>
                <div className="text-right">
                  <div className="font-mono font-bold">{formatSOL(stats.referralEarnings)}</div>
                  <div className="text-xs text-slate-400">{formatUSD(stats.referralEarnings)}</div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <CheckSquare className="w-4 h-4 text-green-400" />
                  <span className="text-sm text-slate-300">Tasks</span>
                </div>
                <div className="text-right">
                  <div className="font-mono font-bold">{formatSOL(stats.taskEarnings)}</div>
                  <div className="text-xs text-slate-400">{formatUSD(stats.taskEarnings)}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Last Activity */}
          <div className="flex items-center justify-between text-sm text-slate-400 bg-slate-800/30 rounded-lg px-4 py-3 hover:bg-slate-800/50 transition-colors">
            <span>Last withdrawal</span>
            <span>{new Date(stats.lastWithdrawal).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}