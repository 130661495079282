import { useState, useEffect } from 'react';
import { db } from '../firebase'; // Your Firebase instance
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from './useAuth'; // Import your useAuth hook

// Custom hook to check if the logged-in user is an admin
export const useAdminAuth = () => {
  const { user } = useAuth(); // Get the user object from your useAuth hook
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null); // Admin status
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state (if necessary)

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid); // Reference to the user document
          const docSnap = await getDoc(userRef); // Get the document

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setIsAdmin(userData?.role === 'admin'); // Set isAdmin based on user role
          } else {
            setIsAdmin(false); // If no user data, set isAdmin to false
          }
        } catch (error) {
          console.error('Error fetching admin data:', error);
          setError('Error fetching admin status');
          setIsAdmin(false); // In case of error, we assume the user is not an admin
        }
      } else {
        setIsAdmin(false); // If no user is logged in, set isAdmin to false
      }
      setLoading(false); // Stop loading
    };

    checkAdmin();
  }, [user]); // Re-run when user changes

  return { isAdmin, loading, error }; // Return the result
};
