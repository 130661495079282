import React, { useState } from 'react';
import { X, Upload, AlertCircle } from 'lucide-react';
import toast from 'react-hot-toast';

interface TaskProofPopupProps {
  isOpen: boolean;
  onClose: () => void;
  task: {
    title: string;
    proofType: string;
    proofInstructions: string;
  };
  onSubmit: (proof: string) => Promise<void>;
}

export function TaskProofPopup({ isOpen, onClose, task, onSubmit }: TaskProofPopupProps) {
  const [proof, setProof] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!proof.trim()) {
      toast.error('Please provide proof to complete this task');
      return;
    }

    setLoading(true);
    try {
      await onSubmit(proof);
      toast.success('Proof submitted successfully! Waiting for admin verification.');
      onClose();
    } catch (error) {
      toast.error('Failed to submit proof. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-slate-900 rounded-2xl w-full max-w-md border border-slate-800">
        <div className="p-6">
          <div className="flex justify-between items-start mb-4">
            <h3 className="text-xl font-bold">{task.title}</h3>
            <button onClick={onClose} className="text-slate-400 hover:text-white">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-4 mb-6">
            <div className="flex items-start gap-3">
              <AlertCircle className="w-5 h-5 text-blue-400 mt-0.5" />
              <div>
                <h4 className="font-medium text-blue-400">Proof Required</h4>
                <p className="text-sm text-slate-400 mt-1">
                  {task.proofInstructions}
                </p>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">Your Proof</label>
              {task.proofType === 'screenshot' ? (
                <input
                  type="url"
                  value={proof}
                  onChange={(e) => setProof(e.target.value)}
                  className="w-full bg-slate-800 rounded-lg px-4 py-3"
                  placeholder="Enter screenshot URL"
                  required
                />
              ) : (
                <textarea
                  value={proof}
                  onChange={(e) => setProof(e.target.value)}
                  className="w-full bg-slate-800 rounded-lg px-4 py-3"
                  placeholder={`Enter your ${task.proofType}`}
                  rows={4}
                  required
                />
              )}
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold disabled:opacity-50 flex items-center justify-center gap-2"
            >
              {loading ? (
                'Submitting...'
              ) : (
                <>
                  <Upload className="w-4 h-4" />
                  Submit Proof
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}