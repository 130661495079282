import { trackEvent, AnalyticsEvents } from '../firebase';

export const analyticsService = {
  trackLogin: (email: string) => {
    trackEvent(AnalyticsEvents.LOGIN, { email });
  },

  trackRegistration: (email: string) => {
    trackEvent(AnalyticsEvents.REGISTER, { email });
  },

  trackTaskCompletion: (taskId: string, userId: string) => {
    trackEvent(AnalyticsEvents.TASK_COMPLETE, { taskId, userId });
  },

  trackWithdrawRequest: (amount: number, userId: string) => {
    trackEvent(AnalyticsEvents.WITHDRAW_REQUEST, { amount, userId });
  },

  trackReferral: (referrerId: string, newUserId: string) => {
    trackEvent(AnalyticsEvents.REFERRAL_CREATED, { referrerId, newUserId });
  },

  trackAdRequest: (userId: string, adType: string) => {
    trackEvent(AnalyticsEvents.AD_REQUEST, { userId, adType });
  },

  trackStreakUpdate: (userId: string, streakCount: number) => {
    trackEvent(AnalyticsEvents.STREAK_UPDATE, { userId, streakCount });
  }
};