import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { X, CheckCircle, AlertTriangle, MessageSquare, Gift, Coins, Star, Newspaper } from 'lucide-react';

interface Notification {
  id: string;
  type: 'task' | 'withdraw' | 'reward' | 'system' | 'message' | 'news' | 'report';
  title: string;
  message: string;
  timestamp: Date;
  read: boolean;
  action?: {
    label: string;
    onClick: () => void;
  };
}

interface NotificationsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  notifications: Notification[];
  onMarkAsRead: (id: string) => void;
  onMarkAllAsRead: () => void;
}

export function NotificationsPopup({
  isOpen,
  onClose,
  notifications,
  onMarkAsRead,
  onMarkAllAsRead,
}: NotificationsPopupProps) {
  if (!isOpen) return null;

  const getIcon = (type: Notification['type']) => {
    switch (type) {
      case 'task': return <Gift className="w-5 h-5 text-purple-400" />;
      case 'withdraw': return <Coins className="w-5 h-5 text-green-400" />;
      case 'reward': return <Star className="w-5 h-5 text-yellow-400" />;
      case 'system': return <Newspaper className="w-5 h-5 text-blue-400" />;
      case 'news': return <Newspaper className="w-5 h-5 text-orange-400" />;
      case 'message': return <MessageSquare className="w-5 h-5 text-teal-400" />;
      case 'report': return <AlertTriangle className="w-5 h-5 text-yellow-400" />;
      default: return null;
    }
  };

  const groupedNotifications = notifications.reduce((acc, notification) => {
    const category = notification.type;
    if (!acc[category]) acc[category] = [];
    acc[category].push(notification);
    return acc;
  }, {} as Record<string, Notification[]>);

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <div
      className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-start justify-center z-50 p-4"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="bg-slate-900 rounded-2xl w-full max-w-md border border-slate-800 mt-20 animate-in zoom-in-95 duration-200">
        <div className="sticky top-0 bg-slate-900 z-10 p-6 border-b border-slate-800">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center gap-2">
                <h3 className="text-xl font-bold text-slate-200">Notifications</h3>
                {unreadCount > 0 && (
                  <span className="bg-purple-500 text-white text-xs px-2 py-0.5 rounded-full">{unreadCount} new</span>
                )}
              </div>
              <p className="text-slate-400 text-sm">Stay updated with your activity</p>
            </div>
            <button onClick={onClose} className="text-slate-400 hover:text-white p-1 hover:bg-slate-800/50 rounded-lg">
              <X className="w-6 h-6" />
            </button>
          </div>

          {unreadCount > 0 && (
            <button onClick={onMarkAllAsRead} className="mt-4 text-sm text-purple-400 hover:text-purple-300 transition-colors">
              Mark all as read
            </button>
          )}
        </div>

        <div className="p-6 space-y-4">
          {Object.keys(groupedNotifications).length === 0 ? (
            <div className="text-center py-8">
              <p className="text-slate-400">No notifications yet</p>
            </div>
          ) : (
            Object.entries(groupedNotifications).map(([category, notifications]) => (
              <div key={category}>
                <div className="text-lg font-semibold text-slate-300">{category.charAt(0).toUpperCase() + category.slice(1)}s</div>
                {notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`group relative bg-slate-800/50 rounded-lg p-4 hover:bg-slate-800/70 transition-all ${
                      !notification.read ? 'ring-1 ring-purple-500/50' : ''
                    }`}
                    onClick={() => !notification.read && onMarkAsRead(notification.id)}
                  >
                    <div className="flex gap-3">
                      <div className="mt-1">{getIcon(notification.type)}</div>
                      <div className="flex-1">
                        <div className="flex items-start justify-between gap-2">
                          <h4 className="font-medium text-slate-200">{notification.title}</h4>
                          <span className="text-xs text-slate-500">
                            {formatDistanceToNow(notification.timestamp, { addSuffix: true })}
                          </span>
                        </div>
                        <p className="text-sm text-slate-400 mt-1">{notification.message}</p>
                      </div>
                    </div>
                    {!notification.read && (
                      <div className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
                        <CheckCircle className="w-4 h-4 text-purple-400" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
