import { useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Import db for Firestore
import { onAuthStateChanged, getAuth, signInWithEmailAndPassword, User as FirebaseUser } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'; // Firestore methods

// Function to check admin status by email in Firestore
export const checkAdminStatus = async (email: string): Promise<boolean> => {
  try {
    const adminRef = doc(db, 'adminUsers', email); // Reference to Firestore adminUsers collection
    const adminDoc = await getDoc(adminRef);

    if (adminDoc.exists()) {
      const adminData = adminDoc.data();
      return adminData?.role === 'admin'; // Check if the role is 'admin'
    }
    return false; // User is not an admin
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false; // Default to false if error occurs
  }
};

export function useAuth() {
  const [user, setUser] = useState<FirebaseUser | null>(null); // User state
  const [isAdmin, setIsAdmin] = useState(false); // Admin status state
  const [loading, setLoading] = useState(true); // Loading state to manage async data

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser); // Set user data

      if (currentUser?.email) {
        try {
          // Check if the user is an admin based on Firestore
          const adminStatus = await checkAdminStatus(currentUser.email); // Use the checkAdminStatus function

          setIsAdmin(adminStatus); // Set the admin status based on the result
        } catch (error) {
          console.error('Error fetching admin status:', error);
          setIsAdmin(false); // Default to false if any error occurs
        }
      } else {
        setIsAdmin(false); // If no user is logged in, set isAdmin to false
      }

      setLoading(false); // Set loading to false once auth state has been determined
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []); // Run once when component mounts

  // Login function using Firebase Authentication
  const login = async (email: string, password: string) => {
    try {
      const authInstance = getAuth();
      await signInWithEmailAndPassword(authInstance, email, password);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // Return user, isAdmin, loading, and login function so that other components can use them
  return { user, isAdmin, loading, login };
}
