import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebase';
import { doc, getDoc, onSnapshot, updateDoc, Timestamp } from 'firebase/firestore';

interface UserStats {
  balance: number;
  daysCompleted: number;
  totalEarned: number;
  referralCount: number;
  referralEarnings: number;
  tasksCompleted: number;
  activeUsers: number;
  lastTaskDate: Timestamp | null;
}

interface AppContextType {
  userStats: UserStats;
  completeTaskForToday: () => Promise<void>;
  fetchUserStats: () => Promise<void>;
}

const defaultStats: UserStats = {
  balance: 0,
  daysCompleted: 0,
  totalEarned: 0,
  referralCount: 0,
  referralEarnings: 0,
  tasksCompleted: 0,
  activeUsers: 0,
  lastTaskDate: null
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [userStats, setUserStats] = useState<UserStats>(defaultStats);
  const { user } = useAuth();

  // Function to manually fetch user stats
  const fetchUserStats = async () => {
    if (!user) return;

    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUserStats({
          balance: data.balance || 0,
          daysCompleted: data.daysCompleted || 0,
          totalEarned: data.totalEarned || 0,
          referralCount: data.referralCount || 0,
          referralEarnings: data.referralEarnings || 0,
          tasksCompleted: data.tasksCompleted || 0,
          activeUsers: data.activeUsers || 0,
          lastTaskDate: data.lastTaskDate || null
        });
      }
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  };

  // Function to mark task as completed for today
  const completeTaskForToday = async () => {
    if (!user) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of today

    const lastTaskDate = userStats.lastTaskDate ? userStats.lastTaskDate.toDate() : null;
    const isTaskCompletedToday = lastTaskDate && lastTaskDate.getTime() === today.getTime();

    const updatedDaysCompleted = isTaskCompletedToday
      ? userStats.daysCompleted
      : userStats.daysCompleted + 1;

    try {
      await updateDoc(doc(db, 'users', user.uid), {
        daysCompleted: updatedDaysCompleted,
        lastTaskDate: Timestamp.fromDate(today)
      });
      setUserStats((prevStats) => ({
        ...prevStats,
        daysCompleted: updatedDaysCompleted,
        lastTaskDate: Timestamp.fromDate(today)
      }));
    } catch (error) {
      console.error('Error updating task completion:', error);
    }
  };

  useEffect(() => {
    if (!user) {
      setUserStats(defaultStats);
      return;
    }

    // Subscribe to real-time updates of user stats
    const unsubscribe = onSnapshot(
      doc(db, 'users', user.uid),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const lastTaskDate = data.lastTaskDate ? data.lastTaskDate.toDate() : null;

          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const shouldResetDaysCompleted = lastTaskDate && lastTaskDate.getTime() !== today.getTime();
          setUserStats({
            balance: data.balance || 0,
            daysCompleted: shouldResetDaysCompleted ? 0 : data.daysCompleted || 0,
            totalEarned: data.totalEarned || 0,
            referralCount: data.referralCount || 0,
            referralEarnings: data.referralEarnings || 0,
            tasksCompleted: data.tasksCompleted || 0,
            activeUsers: data.activeUsers || 0,
            lastTaskDate: data.lastTaskDate || null
          });
        }
      },
      (error) => {
        console.error('Error fetching user stats in real-time:', error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <AppContext.Provider value={{ userStats, fetchUserStats, completeTaskForToday }}>
      {children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}
