import React, { useState } from 'react';
import { AlertCircle, HelpCircle, FileText } from 'lucide-react'; // Using icons from lucide-react

interface SupportTicketPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SupportTicketPopup({ isOpen, onClose }: SupportTicketPopupProps) {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [customTag, setCustomTag] = useState('');
  const [ticketSubmitted, setTicketSubmitted] = useState(false);
  const [ticketNumber, setTicketNumber] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      setIsSubmitting(true);
      setErrorMessage('');
      const ticketId = Math.floor(Math.random() * 10000); // Generate a random ticket number

      // Send ticket to Discord webhook
      const webhookUrl = 'https://discord.com/api/webhooks/1305473995776196618/GprVlocoe6QeWbwskDtAUCJUauzezW8StUJphjDMQ5njR4NYYsuP2PxvizIwYDJJMXST';
      const payload = {
        content: '',
        embeds: [
          {
            title: `New Support Ticket: #${ticketId}`,
            description: message,
            fields: [
              { name: 'Tag', value: selectedTag || customTag || 'None', inline: true },
              { name: 'Status', value: 'Open', inline: true },
              { name: 'Ticket Number', value: `#${ticketId}`, inline: true },
            ],
            color: 3066993, // Green color for success
            thumbnail: {
              url: 'https://example.com/icon.png', // You can replace this with a valid image URL for the icon
            },
            footer: {
              text: `Submitted by User #${ticketId}`,
            },
          },
        ],
      };

      try {
        const res = await fetch(webhookUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (res.ok) {
          setTicketNumber(ticketId.toString());
          setTicketSubmitted(true);
          setMessage(''); // Clear message after submit
          setIsSubmitting(false);
          setTimeout(() => {
            onClose(); // Close the popup after a brief moment
          }, 2000);
        } else {
          throw new Error('Failed to send ticket to Discord');
        }
      } catch (err) {
        console.error('Error sending ticket to Discord:', err);
        setIsSubmitting(false);
        setErrorMessage('There was an issue submitting your ticket. Please try again.');
      }
    } else {
      setErrorMessage('Please enter a message before submitting.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-slate-800 p-6 rounded-lg shadow-lg w-[500px] max-w-full transition-transform transform scale-95 hover:scale-100 duration-300 ease-in-out relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-white text-lg hover:text-red-500"
        >
          &times;
        </button>

        {/* Title and Icon */}
        <div className="flex items-center gap-3 mb-6">
          <HelpCircle className="w-8 h-8 text-purple-500" />
          <h2 className="text-2xl font-bold text-white">Submit a Support Ticket</h2>
        </div>

        <p className="text-sm text-slate-400 mb-4">
          Please describe your issue in detail, and select a tag that best fits your problem. You can also add your own custom tag.
        </p>

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <label className="block text-sm text-slate-400 mb-2">Message:</label>
          <textarea
            className="w-full p-4 bg-slate-700 border border-slate-600 text-white rounded-lg mb-4 placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Describe your issue..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
          />
          
          {/* Tags Section */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div
              className={`${
                selectedTag === 'technical' ? 'bg-blue-500' : 'bg-slate-700'
              } text-white p-3 rounded-lg cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-blue-500 hover:scale-105`}
              onClick={() => setSelectedTag('technical')}
            >
              <FileText className="w-5 h-5 mr-2 inline" /> Technical Issue
            </div>
            <div
              className={`${
                selectedTag === 'billing' ? 'bg-blue-500' : 'bg-slate-700'
              } text-white p-3 rounded-lg cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-blue-500 hover:scale-105`}
              onClick={() => setSelectedTag('billing')}
            >
              <FileText className="w-5 h-5 mr-2 inline" /> Billing
            </div>
            <div
              className={`${
                selectedTag === 'account' ? 'bg-blue-500' : 'bg-slate-700'
              } text-white p-3 rounded-lg cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-blue-500 hover:scale-105`}
              onClick={() => setSelectedTag('account')}
            >
              <FileText className="w-5 h-5 mr-2 inline" /> Account Issues
            </div>
            <div
              className={`${
                customTag ? 'bg-blue-500' : 'bg-slate-700'
              } text-white p-3 rounded-lg cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-blue-500 hover:scale-105`}
            >
              <input
                type="text"
                placeholder="Custom Tag"
                value={customTag}
                onChange={(e) => setCustomTag(e.target.value)}
                className="bg-slate-700 text-white w-full p-2 border border-slate-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder:text-slate-400"
              />
            </div>
          </div>

          {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full py-3 rounded-lg font-semibold text-white transition duration-300 ${
              isSubmitting ? 'bg-gray-500' : 'bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600'
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Ticket'}
          </button>
        </form>

        {/* Green Popup after Submission */}
        {ticketSubmitted && ticketNumber && (
          <div className="mt-4 p-4 bg-green-500 text-white rounded-lg">
            <p className="font-semibold">Your ticket has been submitted!</p>
            <p>Ticket Number: <span className="font-bold">#{ticketNumber}</span></p>
            <p className="text-sm">You can contact us via email or Discord with your ticket number for updates.</p>
          </div>
        )}
      </div>
    </div>
  );
}
