import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC0Tcta3YTSfcHtADqM3zPpJeo1xABXWps",
  authDomain: "dailysolly-8b36a.firebaseapp.com",
  projectId: "dailysolly-8b36a",
  storageBucket: "dailysolly-8b36a.firebasestorage.app",
  messagingSenderId: "822478663687",
  appId: "1:822478663687:web:b59213a601dea53e8bbaa2",
  measurementId: "G-4R8LXVQ4Z6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

// Analytics Events enum
export const AnalyticsEvents = {
  LOGIN: 'user_login',
  REGISTER: 'user_register',
  TASK_COMPLETE: 'task_complete',
  WITHDRAW_REQUEST: 'withdraw_request',
  REFERRAL_CREATED: 'referral_created',
  AD_REQUEST: 'ad_request',
  ADMIN_LOGIN: 'admin_login',
  STREAK_UPDATE: 'streak_update'
} as const;

// Initialize the admin user (set their role to 'admin' in Firestore)
export const initializeAdminUser = async (email: string) => {
  try {
    // Reference to the adminUsers collection in Firestore
    const adminRef = doc(db, 'adminUsers', email);
    const adminDoc = await getDoc(adminRef);

    if (adminDoc.exists()) {
      // If the admin document already exists, update their lastLogin timestamp
      await updateDoc(adminRef, {
        lastLogin: serverTimestamp()
      });
    } else {
      // If the admin doesn't exist, create a new document for them with the role 'admin'
      await setDoc(adminRef, {
        email,
        role: 'admin',
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp()
      });
    }
    
    // Track admin login event in Analytics
    trackEvent(AnalyticsEvents.ADMIN_LOGIN, { email });
  } catch (error) {
    console.error('Error initializing admin user:', error);
  }
};

// Check if the user has an 'admin' role based on their email in the adminUsers collection
export const checkAdminStatus = async (email: string): Promise<boolean> => {
  if (!email) return false;
  
  try {
    const adminRef = doc(db, 'adminUsers', email);
    const adminDoc = await getDoc(adminRef);
    
    if (adminDoc.exists()) {
      const adminData = adminDoc.data();
      return adminData?.role === 'admin';  // Check if the role is 'admin'
    } else {
      return false;  // Return false if the user does not exist in the adminUsers collection
    }
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

// Track events with Firebase Analytics
export const trackEvent = (eventName: string, params?: Record<string, any>) => {
  try {
    logEvent(analytics, eventName, params);
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

// Generate a random referral code for users
export const generateReferralCode = () => {
  return Math.random().toString(36).substring(2, 8).toUpperCase();  // Random referral code generation
};

// Utility function to get the current timestamp
export const createTimestamp = () => serverTimestamp();

// Utility function to get the current timestamp
export const getCurrentTimestamp = () => serverTimestamp();
