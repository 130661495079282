import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { HomePage } from './pages/HomePage';
import { TasksPage } from './pages/TasksPage';
import { ProfilePage } from './pages/ProfilePage';
import { WithdrawPage } from './pages/WithdrawPage';
import { ReferralPage } from './pages/ReferralPage';
import { AdminPanel } from './pages/AdminPanel';
import { useAuth } from './hooks/useAuth';
import { useAdminAuth } from './hooks/useAdminAuth'; // Import the custom admin hook
import { AppProvider } from './contexts/AppContext';
import { NotificationProvider } from './contexts/NotificationContext';

// PrivateRoute for non-admin users (must be logged in)
function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-950 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500" />
      </div>
    );
  }

  // If the user is authenticated, render children, otherwise redirect to login
  return user ? <>{children}</> : <Navigate to="/login" replace />;
}

// AdminRoute for protected admin pages (only accessible by admin)
function AdminRoute({ children }: { children: React.ReactNode }) {
  const { isAdmin, loading } = useAdminAuth();

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-950 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500" />
      </div>
    );
  }

  // If the user is an admin, render children, otherwise redirect to home page
  return isAdmin ? <>{children}</> : <Navigate to="/home" replace />;
}

export default function App() {
  return (
    <AppProvider>
      <NotificationProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />

          {/* Protected Routes for regular users */}
          <Route path="/home" element={<PrivateRoute><Layout><HomePage /></Layout></PrivateRoute>} />
          <Route path="/tasks" element={<PrivateRoute><Layout><TasksPage /></Layout></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Layout><ProfilePage /></Layout></PrivateRoute>} />
          <Route path="/withdraw" element={<PrivateRoute><Layout><WithdrawPage /></Layout></PrivateRoute>} />
          <Route path="/referral" element={<PrivateRoute><Layout><ReferralPage /></Layout></PrivateRoute>} />

          {/* Admin-Only Route */}
          <Route path="/admin" element={<AdminRoute><Layout><AdminPanel /></Layout></AdminRoute>} />
        </Routes>
      </NotificationProvider>
    </AppProvider>
  );
}
