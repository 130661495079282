import React, { useState } from 'react';
import { Tag, Clock, ArrowUpRight, TrendingUp, Gift, Users, Shield, Wallet } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

interface NewsItem {
  id: string;
  title: string;
  description: string;
  imageUrl?: string;
  link?: string;
  tags: string[];
  timestamp: Date;
  featured?: boolean;
}

const initialNews: NewsItem[] = [
  {
    id: '1',
    title: 'DailySolly Reaches 10,000 Active Users!',
    description: 'A major milestone for our community! Over 10,000 users are now actively earning SOL rewards daily. See how our platform has grown and what\'s coming next.',
    imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80',
    link: '#',
    tags: ['milestone', 'community'],
    timestamp: new Date(),
    featured: true,
  },
  {
    id: '2',
    title: 'New Task System Launch',
    description: 'Introducing our revamped task system with higher rewards and more ways to earn. Complete social media tasks, content creation, and community engagement activities.',
    imageUrl: 'https://images.unsplash.com/photo-1553729459-efe14ef6055d?auto=format&fit=crop&q=80',
    link: '#',
    tags: ['update', 'tasks'],
    timestamp: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
  },
];

export function NewsSection() {
  const [news] = useState<NewsItem[]>(initialNews);

  const getTagIcon = (tag: string) => {
    switch (tag) {
      case 'update':
        return <Gift className="w-3 h-3" />;
      case 'community':
        return <Users className="w-3 h-3" />;
      case 'rewards':
        return <TrendingUp className="w-3 h-3" />;
      case 'security':
        return <Shield className="w-3 h-3" />;
      case 'payments':
        return <Wallet className="w-3 h-3" />;
      default:
        return <Tag className="w-3 h-3" />;
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {news.slice(0, 2).map(item => (
        <div
          key={item.id}
          className="bg-gradient-to-br from-purple-500/10 via-slate-800/50 to-blue-500/10 rounded-xl overflow-hidden border border-slate-800/50 hover:border-purple-500/20 transition-colors group h-full"
        >
          {item.imageUrl && (
            <div className="relative h-48 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900/90" />
              <img
                src={item.imageUrl}
                alt={item.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute bottom-4 left-4 right-4">
                <div className="flex gap-2 mb-2">
                  {item.tags.map(tag => (
                    <span
                      key={tag}
                      className="bg-purple-500/20 text-purple-300 px-2 py-0.5 rounded-full text-xs flex items-center gap-1"
                    >
                      {getTagIcon(tag)}
                      {tag}
                    </span>
                  ))}
                </div>
                <h3 className="text-lg font-bold text-white">{item.title}</h3>
              </div>
            </div>
          )}
          <div className="p-4">
            <p className="text-slate-300 mb-3">{item.description}</p>
            <div className="flex items-center justify-between text-sm">
              <span className="text-slate-400 flex items-center gap-1">
                <Clock className="w-4 h-4" />
                {formatDistanceToNow(item.timestamp, { addSuffix: true })}
              </span>
              <a
                href={item.link}
                className="flex items-center gap-1 text-purple-400 hover:text-purple-300 group/link"
              >
                Read more
                <ArrowUpRight className="w-4 h-4 group-hover/link:translate-x-0.5 group-hover/link:-translate-y-0.5 transition-transform" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
