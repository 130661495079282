import { useState } from 'react';
import { DollarSign, ExternalLink, Send, AlertCircle, ArrowUpRight, Users, Clock } from 'lucide-react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';

const SOLANA_ADDRESS = '3FUX7BHaY43LT1dJE22bPjHKXJhZRnX1wXjstHhUGo9K';
const DISCORD_WEBHOOK_URL = 'https://discord.com/api/webhooks/1305181813769109565/teqfwDFM1cBQPVY4B3Y7pvYYzOj_c5Z-nYVrQXWslRkcSVZuMgs5ybtE1H1p4GuTaGez';

const demoPromotion = {
  id: 'promo-1',
  title: 'Solana NFT Collection Launch',
  description: 'Exclusive preview of our upcoming NFT collection. Join the whitelist and get early access to mint.',
  link: 'https://example.com/nft-launch',
  author: 'CryptoArtist',
  views: 1247,
  timestamp: new Date(Date.now() - 2 * 60 * 60 * 1000),
  imageUrl: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80'
};

// Modal Component
function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-slate-900 p-6 rounded-lg w-full max-w-md shadow-lg">
        <button className="text-slate-400 hover:text-slate-200 mb-4 self-end" onClick={onClose}>
          Close
        </button>
        {children}
      </div>
    </div>
  );
}

export function PromoteSection() {
  const [transactionId, setTransactionId] = useState('');
  const [adTitle, setAdTitle] = useState('');
  const [adDescription, setAdDescription] = useState('');
  const [adLink, setAdLink] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Updated validation to accept either the full Solscan URL or just the transaction ID
    if (!/^https:\/\/solscan\.io\/tx\/[a-zA-Z0-9]{43,}$/.test(transactionId) &&
        !/^[a-zA-Z0-9]{43,}$/.test(transactionId)) {
      toast.error('Please enter a valid Solana transaction ID or Solscan link.');
      return;
    }

    if (!/^https?:\/\/.+\..+/.test(adLink)) {
      toast.error('Please enter a valid URL for your ad link.');
      return;
    }

    setIsSubmitting(true);

    try {
      // Add ad request to Firestore
      const docRef = await addDoc(collection(db, 'adRequests'), {
        transactionId,
        title: adTitle,
        description: adDescription,
        link: adLink,
        status: 'pending',
        createdAt: serverTimestamp(),
        ticketNumber: `AD-${Date.now().toString(36).toUpperCase()}`
      });

      toast.success(`Ad submitted for review! Your Ticket Number: ${docRef.id}`);
      
      // Send details to Discord webhook
      await fetch(DISCORD_WEBHOOK_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          content: '**New Ad Submission**',
          embeds: [
            {
              title: 'Ad Submission Details',
              fields: [
                { name: 'Transaction ID', value: transactionId, inline: true },
                { name: 'Title', value: adTitle, inline: true },
                { name: 'Description', value: adDescription, inline: false },
                { name: 'Link', value: adLink, inline: false },
                { name: 'Ticket Number', value: `AD-${Date.now().toString(36).toUpperCase()}`, inline: true }
              ],
              color: 7506394 // Optional: Adds color to the embed
            }
          ]
        })
      });

      // Reset form fields after successful submission
      setTransactionId('');
      setAdTitle('');
      setAdDescription('');
      setAdLink('');
      setShowForm(false);
    } catch (error) {
      toast.error('Failed to submit ad request. Please try again.');
      console.error('Error submitting ad:', error.message || error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Current Promotions */}
      <div className="bg-gradient-to-br from-purple-500/10 via-slate-900/50 to-blue-500/10 rounded-xl border border-slate-800/50 p-6 shadow-lg">
        <h2 className="text-xl font-bold mb-4 text-slate-200">Featured Promotion</h2>
        <div className="bg-slate-800/50 rounded-lg overflow-hidden hover:bg-slate-800/70 transition-all group">
          {demoPromotion.imageUrl && (
            <div className="relative h-48 overflow-hidden">
              <img
                src={demoPromotion.imageUrl}
                alt={demoPromotion.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-slate-900/90 via-slate-900/50 to-transparent" />
              <div className="absolute bottom-4 left-4 right-4">
                <h3 className="text-lg font-bold text-white mb-2">{demoPromotion.title}</h3>
                <p className="text-slate-300 text-sm">{demoPromotion.description}</p>
              </div>
            </div>
          )}
          <div className="p-4">
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center gap-4">
                <span className="text-slate-400 flex items-center gap-1">
                  <Users className="w-4 h-4" />
                  {demoPromotion.views.toLocaleString()} views
                </span>
                <span className="text-slate-400 flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  {new Date(demoPromotion.timestamp).toLocaleDateString()}
                </span>
              </div>
              <a
                href={demoPromotion.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1 text-purple-400 hover:text-purple-300 group/link"
              >
                Visit Site
                <ArrowUpRight className="w-4 h-4 group-hover/link:translate-x-0.5 group-hover/link:-translate-y-0.5 transition-transform" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Promotion Button */}
      <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6 shadow-lg">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-purple-500/10 rounded-lg">
            <DollarSign className="w-6 h-6 text-purple-500" />
          </div>
          <div>
            <h2 className="text-xl font-bold text-slate-200">Promote Your Content</h2>
            <p className="text-slate-400 text-sm">
              Elevate your content by featuring it on DailySolly. Gain visibility and attract more engagement from our vibrant community.
            </p>
          </div>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="w-full bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold flex items-center justify-center gap-2 transition-transform transform hover:scale-105"
        >
          <Send className="w-5 h-5" />
          Start Promotion
        </button>
      </div>

      {/* Promotion Form Modal */}
      <Modal isOpen={showForm} onClose={() => setShowForm(false)}>
        <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-4 mb-6">
          <div className="flex items-start gap-3">
            <AlertCircle className="w-5 h-5 text-yellow-500 mt-0.5" />
            <div>
              <h3 className="font-medium text-yellow-500">Promotion Fee: 10 USD in SOL</h3>
              <p className="text-sm text-slate-400 mt-1">
                Please send exactly 10 USD worth of SOL to the following address to initiate your promotion:
              </p>
              <div className="flex items-center gap-2 mt-2">
                <code className="bg-slate-800/50 px-3 py-1.5 rounded text-sm font-mono flex-1 break-all">
                  {SOLANA_ADDRESS}
                </code>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(SOLANA_ADDRESS);
                    toast.success('Solana address copied to clipboard!');
                  }}
                  className="p-2 hover:bg-slate-800/50 rounded-lg transition-colors"
                  aria-label="Copy Solana Address"
                >
                  <ExternalLink className="w-4 h-4 text-slate-300" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="transactionId" className="block text-sm font-medium text-slate-200 mb-2">
              Solscan Transaction Link or ID
            </label>
            <input
              id="transactionId"
              type="text"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              className="w-full bg-slate-800/50 rounded-lg px-4 py-3 text-slate-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Enter your Solscan transaction link"
              required
            />
          </div>
          <div>
            <label htmlFor="adTitle" className="block text-sm font-medium text-slate-200 mb-2">
              Ad Title
            </label>
            <input
              id="adTitle"
              type="text"
              value={adTitle}
              onChange={(e) => setAdTitle(e.target.value)}
              className="w-full bg-slate-800/50 rounded-lg px-4 py-3 text-slate-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Enter your ad title (max 50 characters)"
              maxLength={50}
              required
            />
          </div>
          <div>
            <label htmlFor="adDescription" className="block text-sm font-medium text-slate-200 mb-2">
              Ad Description
            </label>
            <textarea
              id="adDescription"
              value={adDescription}
              onChange={(e) => setAdDescription(e.target.value)}
              className="w-full bg-slate-800/50 rounded-lg px-4 py-3 text-slate-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Enter your ad description (max 200 characters)"
              rows={3}
              maxLength={200}
              required
            />
          </div>
          <div>
            <label htmlFor="adLink" className="block text-sm font-medium text-slate-200 mb-2">
              Ad Link
            </label>
            <input
              id="adLink"
              type="url"
              value={adLink}
              onChange={(e) => setAdLink(e.target.value)}
              className="w-full bg-slate-800/50 rounded-lg px-4 py-3 text-slate-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="https://your-website.com"
              required
            />
          </div>
          <div className="flex gap-3">
            <button
              type="submit"
              disabled={isSubmitting}
              className="flex-1 bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold flex items-center justify-center gap-2 transition-transform transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Submitting...' : 'Submit for Review'}
            </button>
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="flex-1 px-6 py-3 bg-slate-800/50 hover:bg-slate-800 rounded-lg font-semibold text-slate-200 transition-colors"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
