import { db, getCurrentTimestamp } from '../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { analyticsService } from './analytics';

export const streakService = {
  checkStreak: async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) return { broken: false, days: 0 };

      const userData = userDoc.data();
      const lastTaskDate = userData.lastTaskCompletion?.toDate() || new Date(0);
      const today = new Date();
      
      // Check if more than one day has passed since last task
      const daysDiff = Math.floor((today.getTime() - lastTaskDate.getTime()) / (1000 * 60 * 60 * 24));
      const streakBroken = daysDiff > 1;
      
      return {
        broken: streakBroken,
        days: userData.streak || 0
      };
    } catch (error) {
      console.error('Error checking streak:', error);
      return { broken: false, days: 0 };
    }
  },

  updateStreak: async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) return;

      const userData = userDoc.data();
      const lastTaskDate = userData.lastTaskCompletion?.toDate() || new Date(0);
      const today = new Date();
      
      // Check if last task was completed yesterday
      const isConsecutive = (today.getDate() - lastTaskDate.getDate()) === 1;
      
      await updateDoc(userRef, {
        streak: isConsecutive ? (userData.streak || 0) + 1 : 1,
        lastTaskCompletion: getCurrentTimestamp()
      });

      analyticsService.logUserAction('TASK_COMPLETE', {
        userId,
        newStreak: isConsecutive ? (userData.streak || 0) + 1 : 1
      });
    } catch (error) {
      console.error('Error updating streak:', error);
    }
  },

  resetStreak: async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        streak: 0,
        lastTaskCompletion: getCurrentTimestamp()
      });
    } catch (error) {
      console.error('Error resetting streak:', error);
    }
  }
};