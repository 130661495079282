import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import toast from 'react-hot-toast';

export function AdminPanel() {
  const { user, isAdmin } = useAuth(); // Get current user and admin status
  const [taskSubmissions, setTaskSubmissions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // If the user is not an admin, redirect them
  useEffect(() => {
    if (!isAdmin) {
      toast.error('You do not have permission to access the admin panel');
      // Redirect or show an error message here (e.g. navigate to home)
    }
  }, [isAdmin]);

  // Load pending tasks from Firestore
  useEffect(() => {
    const loadPendingTasks = async () => {
      setLoading(true); // Start loading state
      try {
        const taskQuery = collection(db, 'taskSubmissions');
        const snapshot = await getDocs(taskQuery);
        const tasks = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter tasks that are still pending
        const pendingTasks = tasks.filter((task: any) => task.status === 'pending');
        setTaskSubmissions(pendingTasks);
      } catch (error) {
        console.error('Error loading tasks:', error);
        toast.error('Failed to load tasks');
      } finally {
        setLoading(false); // End loading state
      }
    };

    if (isAdmin) {
      loadPendingTasks(); // Only load tasks if the user is an admin
    }
  }, [isAdmin]);

  // Handle task approval
  const handleApprove = async (taskId: string) => {
    setLoading(true); // Start loading state for approval
    try {
      const taskRef = doc(db, 'taskSubmissions', taskId);
      await updateDoc(taskRef, {
        status: 'approved',
      });
      toast.success('Task approved!');
      setTaskSubmissions((prev) => prev.filter((task) => task.id !== taskId)); // Remove from list
    } catch (error) {
      console.error('Error approving task:', error);
      toast.error('Failed to approve task');
    } finally {
      setLoading(false); // End loading state after approval
    }
  };

  // Handle task decline
  const handleDecline = async (taskId: string) => {
    setLoading(true); // Start loading state for decline
    try {
      const taskRef = doc(db, 'taskSubmissions', taskId);
      await updateDoc(taskRef, {
        status: 'declined',
      });
      toast.success('Task declined!');
      setTaskSubmissions((prev) => prev.filter((task) => task.id !== taskId)); // Remove from list
    } catch (error) {
      console.error('Error declining task:', error);
      toast.error('Failed to decline task');
    } finally {
      setLoading(false); // End loading state after decline
    }
  };

  // If the user is not an admin, return null or show a message
  if (!isAdmin) {
    return <p className="text-red-500">You do not have permission to view this page.</p>;
  }

  return (
    <div className="space-y-6 p-6">
      <h1 className="text-2xl font-bold">Admin Panel</h1>

      {/* Loading Spinner */}
      {loading && (
        <div className="min-h-screen bg-slate-950 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500" />
        </div>
      )}

      {/* No Pending Tasks Message */}
      {!loading && taskSubmissions.length === 0 && <p className="text-slate-400">No pending tasks.</p>}

      {/* List of Pending Tasks */}
      <div className="space-y-4">
        {!loading &&
          taskSubmissions.map((task) => (
            <div
              key={task.id}
              className="bg-slate-800 p-4 rounded-lg flex justify-between items-start space-x-4"
            >
              <div className="flex-1">
                <h2 className="text-xl text-white">{task.title}</h2>
                <p className="text-slate-400">{task.message}</p>
                <p className="text-slate-300 text-sm mt-2">Submitted by: {task.userEmail}</p>
                {/* Add a button to view the submission proof */}
                {task.proof && (
                  <a
                    href={task.proof}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 text-sm mt-2 block"
                  >
                    View Submission Proof
                  </a>
                )}
              </div>

              {/* Approve/Decline buttons */}
              <div className="flex gap-2">
                <button
                  onClick={() => handleApprove(task.id)}
                  className="bg-green-500 text-white py-1 px-4 rounded-lg hover:bg-green-400"
                  disabled={loading}
                >
                  Approve
                </button>
                <button
                  onClick={() => handleDecline(task.id)}
                  className="bg-red-500 text-white py-1 px-4 rounded-lg hover:bg-red-400"
                  disabled={loading}
                >
                  Decline
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
