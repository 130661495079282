import React from 'react';

interface InactivityPopupProps {
  isOpen: boolean;
  onClose: () => void;
  logoutTime: Date | null;
}

export const InactivityPopup: React.FC<InactivityPopupProps> = ({
  isOpen,
  onClose,
  logoutTime,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-orange-200 rounded-xl p-6 w-96 text-center">
        <h2 className="text-xl font-semibold text-orange-600 mb-2">Session Ended</h2>
        <p className="text-orange-700 mb-4">
          Due to inactivity on the website, you have been logged out at{' '}
          {logoutTime ? logoutTime.toLocaleTimeString() : ''}. You can log in again to continue.
        </p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
        >
          Okay
        </button>
      </div>
    </div>
  );
};
