import React from 'react';
import { Shield, Crown, Star, Rocket, MessageSquare, Palette, Heart, Headphones, Wrench, Award } from 'lucide-react';

interface Badge {
  id: string;
  icon: any;
  label: string;
  color: string;
  bgColor: string;
  description: string;
}

interface ProfileBadgesProps {
  badges: string[];
}

export function ProfileBadges({ badges }: ProfileBadgesProps) {
  const allBadges: Badge[] = [
    {
      id: 'premium',
      icon: Crown,
      label: 'Premium Member',
      color: 'text-yellow-500',
      bgColor: 'bg-yellow-500/10',
      description: 'Exclusive access to premium features'
    },
    {
      id: 'advertiser',
      icon: MessageSquare,
      label: 'Advertiser',
      color: 'text-purple-500',
      bgColor: 'bg-purple-500/10',
      description: 'Active content promoter'
    },
    {
      id: 'early_access',
      icon: Rocket,
      label: 'Early Access',
      color: 'text-blue-500',
      bgColor: 'bg-blue-500/10',
      description: 'First to try new features'
    },
    {
      id: 'vip',
      icon: Star,
      label: 'VIP Member',
      color: 'text-amber-500',
      bgColor: 'bg-amber-500/10',
      description: 'Special VIP privileges'
    },
    {
      id: 'creator',
      icon: Palette,
      label: 'Content Creator',
      color: 'text-pink-500',
      bgColor: 'bg-pink-500/10',
      description: 'Active content contributor'
    },
    {
      id: 'sponsor',
      icon: Heart,
      label: 'Sponsor',
      color: 'text-red-500',
      bgColor: 'bg-red-500/10',
      description: 'Platform sponsor'
    },
    {
      id: 'moderator',
      icon: Shield,
      label: 'Moderator',
      color: 'text-emerald-500',
      bgColor: 'bg-emerald-500/10',
      description: 'Community moderator'
    },
    {
      id: 'staff',
      icon: Wrench,
      label: 'Staff',
      color: 'text-indigo-500',
      bgColor: 'bg-indigo-500/10',
      description: 'DailySolly staff member'
    },
    {
      id: 'support',
      icon: Headphones,
      label: 'Support',
      color: 'text-cyan-500',
      bgColor: 'bg-cyan-500/10',
      description: 'Customer support representative'
    },
    {
      id: 'verified',
      icon: Award,
      label: 'Verified',
      color: 'text-teal-500',
      bgColor: 'bg-teal-500/10',
      description: 'Verified account'
    }
  ];

  const userBadges = allBadges.filter(badge => badges.includes(badge.id));

  if (userBadges.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {userBadges.map((badge) => (
        <div
          key={badge.id}
          className="group relative"
        >
          <div className={`flex items-center gap-1.5 px-2 py-1 rounded-full ${badge.bgColor} ${badge.color}`}>
            <badge.icon className="w-3.5 h-3.5" />
            <span className="text-xs font-medium">{badge.label}</span>
          </div>
          
          {/* Tooltip */}
          <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 rounded text-xs text-slate-200 whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
            {badge.description}
          </div>
        </div>
      ))}
    </div>
  );
}