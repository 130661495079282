import React, { useState } from 'react';
import { AlertCircle, Wallet, CheckCircle, Info, HelpCircle } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { SupportTicketPopup } from '../components/SupportTicketPopup'; // Correct import path

// Transaction Detail Popup Component
export function TransactionDetailPopup({ isOpen, onClose, transaction }: any) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-slate-800 p-6 rounded-lg shadow-lg w-[500px] max-w-full transition-transform transform scale-95 hover:scale-100 duration-300 ease-in-out relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-white text-lg hover:text-red-500"
        >
          &times;
        </button>

        <div className="flex items-center gap-3 mb-4">
          <Wallet className="w-8 h-8 text-purple-500" />
          <h2 className="text-2xl font-bold text-white">Transaction Details</h2>
        </div>

        <div className="bg-slate-700/50 rounded-lg p-4 mb-6">
          <div className="flex justify-between items-center">
            <span className="text-slate-400">Transaction Type</span>
            <span className={`text-xl font-bold ${transaction.type === 'withdrawal' ? 'text-red-500' : 'text-green-500'}`}>
              {transaction.type === 'withdrawal' ? 'Withdrawal' : 'Deposit'}
            </span>
          </div>
          <div className="flex justify-between items-center mt-4">
            <span className="text-slate-400">Amount</span>
            <span className="text-xl font-bold">{transaction.amount} SOL</span>
          </div>
          <div className="flex justify-between items-center mt-4">
            <span className="text-slate-400">Date</span>
            <span className="text-xl font-bold">{transaction.date}</span>
          </div>
          <div className="flex justify-between items-center mt-4">
            <span className="text-slate-400">Status</span>
            <span className="text-xl font-bold">{transaction.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

// Main WithdrawPage Component
export function WithdrawPage() {
  const { userStats } = useApp();
  const canWithdraw = userStats.daysCompleted >= 7;
  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false); // State to control the support popup visibility
  const [isTransactionDetailPopupOpen, setTransactionDetailPopupOpen] = useState(false); // State to control the transaction popup visibility
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null); // State to store the selected transaction

  // Fake transaction history for demo purposes
  const fakeTransactionHistory = [
    { date: '2024-11-01', amount: 1.5, type: 'withdrawal', status: 'Completed', id: '12345' },
    { date: '2024-10-29', amount: 2.0, type: 'deposit', status: 'Pending', id: '12346' },
    { date: '2024-10-28', amount: 0.8, type: 'withdrawal', status: 'Completed', id: '12347' },
    { date: '2024-10-25', amount: 1.2, type: 'deposit', status: 'Completed', id: '12348' },
    { date: '2024-10-20', amount: 3.0, type: 'withdrawal', status: 'Failed', id: '12349' },
  ];

  const openSupportPopup = () => setSupportPopupOpen(true); // Open the support popup
  const closeSupportPopup = () => setSupportPopupOpen(false); // Close the support popup
  const openTransactionDetailPopup = (transaction: any) => {
    setSelectedTransaction(transaction);
    setTransactionDetailPopupOpen(true); // Open the transaction details popup
  };
  const closeTransactionDetailPopup = () => setTransactionDetailPopupOpen(false); // Close the transaction details popup

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Withdraw Funds Section */}
        <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
          <div className="flex items-center gap-3 mb-4">
            <Wallet className="w-8 h-8 text-purple-500" />
            <h2 className="text-2xl font-bold">Withdraw Funds</h2>
          </div>
          <p className="text-sm text-slate-400 mb-4">
            You can withdraw your earnings to your account once you've completed the required tasks.
          </p>
          <div className="bg-slate-800/50 rounded-lg p-4 mb-6">
            <div className="flex justify-between items-center">
              <span className="text-slate-400">Available Balance</span>
              <span className="text-xl font-bold">{userStats.balance} SOL</span>
            </div>
          </div>

          {!canWithdraw ? (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 mb-6">
              <div className="flex items-start gap-3">
                <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
                <div>
                  <h3 className="font-medium text-red-500">Withdrawal Locked</h3>
                  <p className="text-sm text-slate-400">
                    Complete 7 consecutive days of tasks to unlock withdrawals. Current progress: {userStats.daysCompleted}/7 days.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <button className="w-full bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold">
              Request Withdrawal
            </button>
          )}
        </div>

        {/* Transaction History Section */}
        <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
          <div className="flex items-center gap-3 mb-4">
            <CheckCircle className="w-8 h-8 text-green-500" />
            <h2 className="text-2xl font-bold">Transaction History</h2>
          </div>
          <p className="text-sm text-slate-400 mb-4">
            Review your transaction history, including deposits and withdrawals.
          </p>
          <div className="bg-slate-800/50 rounded-lg p-4 mb-6">
            <ul className="space-y-4">
              {fakeTransactionHistory.map((transaction, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center p-3 rounded-lg hover:bg-slate-700 transition duration-300 cursor-pointer"
                  onClick={() => openTransactionDetailPopup(transaction)}
                >
                  <div className="flex items-center gap-2">
                    <span className="text-slate-400">{transaction.date}</span>
                    <span
                      className={`text-sm ${transaction.type === 'withdrawal' ? 'text-red-500' : 'text-green-500'}`}
                    >
                      {transaction.type === 'withdrawal' ? 'Withdrawal' : 'Deposit'}
                    </span>
                  </div>
                  <span
                    className={`text-xl font-bold ${
                      transaction.type === 'withdrawal' ? 'text-red-500' : 'text-green-500'
                    }`}
                  >
                    {transaction.amount} SOL
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Reward Progress Section */}
        <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
          <div className="flex items-center gap-3 mb-4">
            <Info className="w-8 h-8 text-yellow-500" />
            <h2 className="text-2xl font-bold">Reward Progress</h2>
          </div>
          <p className="text-sm text-slate-400 mb-4">
            Track your progress toward completing 7 consecutive days of tasks and unlock the ability to withdraw.
          </p>
          <div className="bg-slate-800/50 rounded-lg p-4 mb-6">
            <div className="flex justify-between items-center">
              <span className="text-slate-400">Days Completed</span>
              <span className="text-xl font-bold">{userStats.daysCompleted}/7</span>
            </div>
            <div className="w-full h-2 bg-gray-500 rounded mt-2">
              <div
                className="h-full bg-gradient-to-r from-purple-500 to-blue-500"
                style={{ width: `${(userStats.daysCompleted / 7) * 100}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
        <div className="flex items-center gap-3 mb-4">
          <HelpCircle className="w-8 h-8 text-blue-500" />
          <h2 className="text-2xl font-bold">Frequently Asked Questions</h2>
        </div>
        <div className="bg-slate-800/50 rounded-lg p-4 mb-6">
          <ul className="space-y-3">
            <li className="text-slate-400">
              <strong>How do I unlock withdrawals?</strong> Complete 7 consecutive days of tasks.
            </li>
            <li className="text-slate-400">
              <strong>What is the minimum withdrawal amount?</strong> The minimum withdrawal amount is 0.01 SOL.
            </li>
            <li className="text-slate-400">
              <strong>Can I change my withdrawal method?</strong> Yes, you can change your method at any time.
            </li>
          </ul>
        </div>
      </div>

      {/* Support Button Section */}
      <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl border border-slate-800 p-6">
        <div className="flex items-center gap-3 mb-4">
          <HelpCircle className="w-8 h-8 text-blue-500" />
          <h2 className="text-2xl font-bold">Need Help?</h2>
        </div>
        <button
          onClick={openSupportPopup}
          className="w-full bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white py-3 rounded-lg font-semibold"
        >
          Contact Support
        </button>
      </div>

      {/* Support Ticket Popup */}
      <SupportTicketPopup isOpen={isSupportPopupOpen} onClose={closeSupportPopup} />

      {/* Transaction Detail Popup */}
      <TransactionDetailPopup
        isOpen={isTransactionDetailPopupOpen}
        onClose={closeTransactionDetailPopup}
        transaction={selectedTransaction}
      />
    </div>
  );
}
