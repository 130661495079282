// AdminLoginPopup.tsx
import React, { useState } from 'react';

interface AdminLoginPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function AdminLoginPopup({ isOpen, onClose, onSuccess }: AdminLoginPopupProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    // Replace with your actual admin credentials for testing
    const adminCredentials = [
      { email: 'info@hutsakeee.nl', password: 'admin123' },
      { email: 'capcut@hutsakeee.nl', password: 'password123' },
      { email: 'bjornlouer2011@gmail.com', password: 'securepass' },
    ];

    // Check if provided email and password match any admin credentials
    const isValidAdmin = adminCredentials.some(
      (cred) => cred.email === email && cred.password === password
    );

    if (isValidAdmin) {
      onSuccess(); // Call the success function if login is successful
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-80">
        <h2 className="text-xl font-bold mb-4">Admin Login</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="flex justify-end space-x-2 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
