import React from 'react';
import { Home, Gift, Users, User, Wallet, Shield } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useAuth();

  const menuItems = [
    { icon: Home, label: 'Home', path: '/home' },
    { icon: Gift, label: 'Tasks', path: '/tasks' },
    { icon: Users, label: 'Referral', path: '/referral' },
    { icon: Wallet, label: 'Withdraw', path: '/withdraw' },
    { icon: User, label: 'Profile', path: '/profile' },
    ...(isAdmin ? [{ icon: Shield, label: 'Admin', path: '/admin' }] : []),
  ] as const;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-slate-900/50 backdrop-blur-lg border-t border-slate-800">
      <div className="container mx-auto px-4">
        <div className="flex justify-between py-3">
          {menuItems.map(({ icon: Icon, label, path }) => (
            <button
              key={label}
              onClick={() => navigate(path)}
              className={`flex flex-col items-center gap-1 transition-colors ${
                location.pathname === path ? 'text-purple-400' : 'text-slate-400 hover:text-purple-400'
              }`}
            >
              <Icon className="w-6 h-6" />
              <span className="text-xs">{label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}