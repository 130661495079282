import React, { useState } from 'react';
import { Sparkles, ArrowRight, XCircle, CheckCircle, Unlock, DollarSign, Link } from 'lucide-react'; 
import { FaTelegramPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export function LandingPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEarlyAccessPopupVisible, setIsEarlyAccessPopupVisible] = useState(false);
  const [paymentProofLink, setPaymentProofLink] = useState('');
  const [ticketNumber, setTicketNumber] = useState<string | null>(null);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);  
  const [errorMessage, setErrorMessage] = useState('');  

  const handleEnterApp = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    if (user) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  };

  const handleEarlyAccessClick = () => {
    setIsEarlyAccessPopupVisible(true);
  };

  const handlePaymentDone = () => {
    // Generate a ticket number before proceeding
    const newTicketNumber = Math.floor(Math.random() * 10000); 
    setTicketNumber(newTicketNumber.toString()); // Store the ticket number in the state

    if (paymentProofLink) {
      // Send to Discord webhook (webhook URL needs to be updated)
      const discordWebhookUrl = 'https://discord.com/api/webhooks/1305141723403391006/cUZyNiiSdFUNUgaSBtrI22FCu8wrfpGRCoLeEIYXG9rwXYmU_w19DwNuKYTSaanUpNfO';
      const payload = {
        content: `New early access payment received! Ticket number: #${newTicketNumber} with payment proof link: ${paymentProofLink}`,
        username: 'DailySolly Bot',
      };

      // Send the webhook with the ticket number and payment proof link
      fetch(discordWebhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Hide the early access popup and show success popup
      setIsEarlyAccessPopupVisible(false);
      setIsSuccessPopupVisible(true);

      // Clear the payment link and error message
      setPaymentProofLink('');
      setErrorMessage('');
    } else {
      alert("Please provide a payment proof link.");
    }
  };

  return (
    <div className="min-h-screen bg-slate-950 flex items-center justify-center p-4">
      <div className="text-center">
        <div className="inline-block p-3 bg-purple-500/10 rounded-full mb-6">
          <Sparkles className="w-12 h-12 text-purple-500" />
        </div>
        <h1 className="text-4xl font-bold mb-3 text-white">Welcome to DailySolly</h1>
        <p className="text-slate-300 mb-8 max-w-md mx-auto">
          Complete daily tasks, earn Solana rewards, and build your crypto portfolio with our community.
        </p>
        <button
          onClick={handleEnterApp}
          className="bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white px-8 py-4 rounded-lg font-semibold flex items-center gap-2 mx-auto text-lg group transition duration-300 ease-in-out transform hover:scale-105"
        >
          Enter App
          <ArrowRight className="w-6 h-6 group-hover:translate-x-1 transition-transform" />
        </button>
        <p className="text-sm text-slate-400 mt-8">
          Created by Dutch influencer Bjorn
        </p>
        
        {/* Telegram Icon Link */}
        <a
          href="https://t.me/dailysolly"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full mt-4 hover:scale-105 transition-transform"
        >
          <FaTelegramPlane className="w-6 h-6 text-white" />
        </a>
      </div>

      {/* Beta Message Popup */}
      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-gradient-to-b from-slate-800 to-slate-900 rounded-2xl w-full max-w-md border border-slate-700/50 shadow-xl p-8 relative">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200 transition duration-300"
            >
              <XCircle className="w-6 h-6" />
            </button>
            <div className="flex items-center gap-3 mb-4">
              <Sparkles className="w-8 h-8 text-purple-400" />
              <h2 className="text-2xl font-bold text-white">Welcome to the Beta Version!</h2>
            </div>
            <p className="text-slate-400 mb-4">
              Thank you for trying out our beta version! Your feedback is invaluable as we continue to improve the app.
            </p>
            <p className="text-slate-400 mb-6">
              If you have any suggestions or encounter any issues, please let us know!
            </p>
            <div className="flex justify-between gap-4">
              <button
                onClick={handleClosePopup}
                className="w-1/2 bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold transition-all duration-200 transform hover:scale-[1.02] flex items-center justify-center gap-2"
              >
                <CheckCircle className="w-6 h-6" />
                Continue
              </button>
              <button
                onClick={handleEarlyAccessClick}
                className="w-1/2 bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white py-3 rounded-lg font-semibold transition-all duration-200 transform hover:scale-[1.02] flex items-center justify-center gap-2"
              >
                <Unlock className="w-6 h-6" />
                Get Early Access
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Early Access Payment Popup */}
      {isEarlyAccessPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-gradient-to-b from-slate-800 to-slate-900 rounded-2xl w-full max-w-md border border-slate-700/50 shadow-xl p-8 relative">
            <button
              onClick={() => setIsEarlyAccessPopupVisible(false)}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200 transition duration-300"
            >
              <XCircle className="w-6 h-6" />
            </button>
            <h2 className="text-2xl font-bold text-white mb-4">Get Early Access</h2>
            <div className="flex items-center gap-2 mb-4">
              <DollarSign className="w-6 h-6 text-green-500" />
              <p className="text-slate-400">
                To get early access, send $50 to the following Solana address:
              </p>
            </div>
            <p className="text-slate-400 mb-4">
              <span className="font-semibold text-white">3FUX7BHaY43LT1dJE22bPjHKXJhZRnX1wXjstHhUGo9K</span>
            </p>
            <div className="flex items-center gap-2 mb-4">
              <Link className="w-6 h-6 text-blue-400" />
              <p className="text-slate-400 mb-4">
                After the payment is made, please provide the payment proof link here:
              </p>
            </div>
            <input
              type="text"
              placeholder="Paste payment proof link"
              value={paymentProofLink}
              onChange={(e) => setPaymentProofLink(e.target.value)}
              className="mb-4 w-full text-sm text-gray-400 px-3 py-2 rounded-md border border-gray-600 bg-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <div className="flex justify-between gap-4">
              <button
                onClick={handlePaymentDone}
                className="w-1/2 bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold transition-all duration-200 transform hover:scale-[1.02] flex items-center justify-center gap-2"
              >
                <CheckCircle className="w-6 h-6" />
                Payment Done
              </button>
              <button
                onClick={() => setIsEarlyAccessPopupVisible(false)}
                className="w-1/2 bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white py-3 rounded-lg font-semibold transition-all duration-200 transform hover:scale-[1.02] flex items-center justify-center gap-2"
              >
                <XCircle className="w-6 h-6" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Payment Popup */}
      {isSuccessPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-gradient-to-b from-green-800 to-green-900 rounded-2xl w-full max-w-md border border-green-700/50 shadow-xl p-8 relative">
            <h2 className="text-2xl font-bold text-white mb-4">Payment Successful!</h2>
            <p className="text-white mb-4">
              Your payment was successful. Your ticket number is: #{ticketNumber}. Please refer to this number when contacting the admin.
            </p>
            <button
              onClick={() => setIsSuccessPopupVisible(false)}
              className="w-full bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white py-3 rounded-lg font-semibold transition-all duration-200 transform hover:scale-[1.02]"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
