import React from 'react';
import { AlertTriangle, RefreshCw } from 'lucide-react';

interface StreakBrokenPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onRestart: () => void;
}

export function StreakBrokenPopup({ isOpen, onClose, onRestart }: StreakBrokenPopupProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-slate-900 rounded-2xl w-full max-w-md border border-slate-800">
        <div className="p-6">
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-red-500/10 rounded-lg">
              <AlertTriangle className="w-8 h-8 text-red-500" />
            </div>
            <h3 className="text-xl font-bold">Streak Broken!</h3>
          </div>
          
          <p className="text-slate-300 mb-6">
            You didn't complete your daily tasks yesterday. Your 7-day streak has been reset.
            Complete daily tasks to maintain your streak and earn rewards!
          </p>

          <div className="flex gap-3">
            <button
              onClick={onRestart}
              className="flex-1 bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white py-3 rounded-lg font-semibold flex items-center justify-center gap-2"
            >
              <RefreshCw className="w-5 h-5" />
              Start New Streak
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}