import React, { useState, useEffect } from 'react';
import { ReferralCard } from '../components/ReferralCard';
import { db, auth } from '../firebase'; // Assuming firebase is initialized
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';  // Correct import

export function ReferralPage() {
  const [user, loading, error] = useAuthState(auth); // Get user from auth state
  const [referralLink, setReferralLink] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      const fetchReferralCode = async () => {
        const userRef = doc(db, 'users', user.uid); // Reference to user's document in Firestore
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          // If referral code exists, retrieve it
          const referralCode = userDoc.data()?.referralCode;
          setReferralLink(`https://dailysolly.eu/referral/${referralCode}`);
        } else {
          // If user doesn't have a referral code, generate and save one
          const referralCode = generateReferralCode();
          await setDoc(userRef, { referralCode }, { merge: true });
          setReferralLink(`https://dailysolly.eu/referral/${referralCode}`);
        }
      };

      fetchReferralCode();
    }
  }, [user]);

  // Function to generate a unique referral code
  const generateReferralCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase(); // Simple random code generator
  };

  return (
    <div className="space-y-6">
      {/* ReferralCard component */}
      <ReferralCard />
    </div>
  );
}
